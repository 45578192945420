interface LoadingDotsProps {
    size?: number;
    text?: string;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ size = 4, text }) => {
    return (
        <div className="pending-cursor">
            <div className="loading-dots">
                <span style={{ height: `${size}px`, width: `${size}px` }} className="dot"></span>
                <span style={{ height: `${size}px`, width: `${size}px` }} className="dot"></span>
                <span style={{ height: `${size}px`, width: `${size}px` }} className="dot"></span>
            </div>
            <p className="text-slate-500 text-sm">{text}</p>
        </div>
    );
};

export default LoadingDots;
