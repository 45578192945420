import "../styles/PromptEngineering.component.scss";
import ArinLayout from "../components/ArinLayout";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import KeycloakClient from "../providers/auth/keycloak-client";

export default function PromptEngineeringView() {
    const [searchParams] = useSearchParams();
    const liteGraphURL = process.env.REACT_APP_LITEGRAPH_URL;
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const handleIframeLoad = async () => {
        const token = await KeycloakClient.getInstance().getAuthUserToken();
        iframeRef.current?.contentWindow?.postMessage({ token }, liteGraphURL || "*");
    };

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.addEventListener("load", handleIframeLoad);
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener("load", handleIframeLoad);
            }
        };
    }, []);

    useEffect(() => {
        const keycloakClient = KeycloakClient.getInstance();

        const handleTokenUpdate = (newToken: string) => {
            iframeRef.current?.contentWindow?.postMessage({ token: newToken }, liteGraphURL || "*");
        };

        keycloakClient.tokenUpdateEmitter.on("tokenUpdated", handleTokenUpdate);

        return () => {
            keycloakClient.tokenUpdateEmitter.off("tokenUpdated", handleTokenUpdate);
        };
    }, []);

    return (
        <ArinLayout>
            <div className="prompt-engineering">
                <iframe title="brain" ref={iframeRef} onLoad={handleIframeLoad} src={`${liteGraphURL}?application_code=${searchParams.get("collaboration_app_group_id")}`} className="prompt-engineer"></iframe>
            </div>
        </ArinLayout>
    );
}
