import LeftCustomTab from "../customTabs/LeftCustomTab";
import PersonaPersonalityTrait from "./PersonaPersonalityTrait";
import PersonaSpecificKnowledge from "./PersonaSpecificKnowledge";
import PersonaWritingSampling from "./PersonaWritingSampling";
import PersonaKnowledge from "./PersonaKnowledge";

// TODO: move to models folder
type PersonaPersonalityProps = {
    moveToNext?: () => void;
    moveToPrevious?: () => void;
};
const PersonaPersonality = ({ moveToNext, moveToPrevious }: PersonaPersonalityProps) => {
    const tabs = [
        {
            label: "Personality Trait",
            content: <PersonaPersonalityTrait />,
        },
        {
            label: "Specific Knowledge",
            content: <PersonaSpecificKnowledge />,
        },
        {
            label: "Writing Sampling",
            content: <PersonaWritingSampling />,
        },
        {
            label: "Knowledge",
            content: <PersonaKnowledge />,
        },
    ];

    return (
        <div className="pt-5 h-full overflow-y-auto">
            <LeftCustomTab tabs={tabs} />
        </div>
    );
};

export default PersonaPersonality;
