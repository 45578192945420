import { Button, FormControl, FormErrorMessage, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useHttpClient } from "../../utils/http.utils";
import { useSelector } from "react-redux";
import { PaymentMethodModel } from "../../models/payment.model";
import { Validators } from "../../utils/validators.utils";
import hotToast from "react-hot-toast";
import { showPaymentIntentStatusMessage } from "../../utils/stripe";
import { PaymentIntent } from "@stripe/stripe-js";
import { config } from "../../config";
import { PaymentMethodOptionLabel } from "../PaymentMethodCard";

interface CreditTopUpFormProps {
    isOpen: boolean;
    onClose: () => void;
}
export default function CreditTopUpForm(props: CreditTopUpFormProps) {
    const purchaseModal = useDisclosure();
    const { createPaymentChargeApi } = useHttpClient();
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;

    const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([]);

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            purchaseModal.onOpen();
        } else {
            purchaseModal.onClose();
        }
        // if payment methods
        if (paymentMethods.length > 0) {
            setPaymentMethodOptions(
                paymentMethods.map((pm) => ({
                    value: pm.stripe_id,
                    label: `**** **** **** ${pm.card_extract}`,
                    card_brand: pm.card_brand,
                    expiry_date: pm.expiry_date,
                })),
            );
        }
    }, [props.isOpen]);

    const closeFormModal = () => {
        purchaseModal.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    /**
     * Form validators
     */
    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    function validateEmail(value) {
        if (!value) {
            return null;
        } else if (!Validators.validateEmail(value)) {
            return "Invalid email";
        }
    }

    const purchaseCredits = async (values, actions) => {
        try {
            setIsSaving(true);
            const { paymentIntent }: { paymentIntent: PaymentIntent } = await createPaymentChargeApi(
                {
                    amount: parseFloat(values.amount),
                    payment_method: values.payment_method,
                    return_url: `${process.env.REACT_APP_URL}/payments/callback/credit-purchase`,
                },
                config.paymentMotive.purchaseCredit,
            );

            /**
             * The data return from the server is the stripe payment intent object,
             *  @see https://stripe.com/docs/api/payment_intents/object
             *
             * We need to do two things, if the next_action, is returned we need to handle that with stripe
             * Check the status of the payment intent
             */
            if (paymentIntent.next_action) {
                // handle next actions
                // @ts-ignore
                const { error, paymentIntent } = await stripe.handleCardAction(paymentIntent.client_secret);
                // If error ocurred, tell user payment could not be completed
                if (error) {
                    hotToast.error(error.message || "We are unable to complete your payment at this time");
                } else {
                    hotToast(showPaymentIntentStatusMessage(paymentIntent.status));
                }
            } else {
                hotToast(showPaymentIntentStatusMessage(paymentIntent.status));
                // If success close modal
                console.log(paymentIntent.status);
                if (paymentIntent.status === "succeeded") {
                    hotToast.success("Your payment was successful");
                }
            }
            closeFormModal();
            setIsSaving(false);
        } catch (e) {
            setIsSaving(false);
            console.error(e); // debug
            hotToast.error("Cannot purchase credits at this time, Please try again later");
        }
    };

    return (
        <Modal isOpen={purchaseModal.isOpen} onClose={closeFormModal} closeOnOverlayClick={!isSaving} size="md" isCentered>
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={{
                        email: "",
                        amount: null,
                        payment_method: "",
                    }}
                    onSubmit={purchaseCredits}
                >
                    {(props) => (
                        <Form>
                            <ModalHeader>Add Credits</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div className="mt-[32px]">
                                    <Field name="email" validate={validateEmail}>
                                        {({ field, form }) => (
                                            <FormControl mb={6} isInvalid={form.errors.email && form.touched.email}>
                                                <p className=" font-medium mb-1">Email</p>
                                                <p className="text-[13px] text-slate-500 mb-2">You can purchase credits for the members of your organization by specifying their email. Otherwise, leave this field empty if you are purchasing credits for yourself.</p>
                                                <Input {...field} placeholder="Email" />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="amount" validate={(e) => validateRequired("Amount", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={6} isInvalid={form.errors.amount && form.touched.amount}>
                                                <p className="text-[13px] font-medium mb-1">Amount</p>
                                                <Input prefix="$" {...field} placeholder="Amount (USD)" />
                                                <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="payment_method" validate={(e) => validateRequired("Payment Method", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={3} isInvalid={form.errors.payment_method && form.touched.payment_method}>
                                                <p className="text-[13px] font-medium mb-1">Payment Method</p>
                                                <Select
                                                    placeholder="Select payment method"
                                                    value={paymentMethodOptions.find((option) => (field.value ? field.value.includes(option.value) : ""))}
                                                    isSearchable={false}
                                                    onChange={(selectedOption) => {
                                                        form.setFieldValue("payment_method", (selectedOption as any)?.value);
                                                    }}
                                                    onBlur={field.onBlur}
                                                    formatOptionLabel={PaymentMethodOptionLabel}
                                                    options={paymentMethodOptions}
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                />
                                                <FormErrorMessage>{form.errors.payment_method}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <Button variant="ghost" mr={3} onClick={closeFormModal}>
                                    Close
                                </Button>
                                <Button isLoading={isSaving} loadingText="Purchasing.." type="submit" color="white" colorScheme="brand">
                                    Purchase
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
}
