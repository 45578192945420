import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatModel } from "../../models/chat.model";

interface ChatSlice {
    isChatLoading: boolean;
    history: string;
    replies: ChatModel[];
}

const initialState: ChatSlice = {
    isChatLoading: false,
    history: "",
    replies: [
        {
            type: "ai",
            isTyping: false,
            isLoading: false,
            message: "Greetings my name is Montague, an Arin from Arcadia. How may I assist you?",
        },
    ],
};

const searchSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setChatLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isChatLoading = action.payload;
        },
        addChatQuery: (state, action: PayloadAction<ChatModel>) => {
            state.replies = [...state.replies, action.payload];
        },
        removeChatQuery: (state, action: PayloadAction<ChatModel>) => {
            state.replies = state.replies.filter((response) => response.id !== action.payload.id);
        },
        updateChatQuery: (state, action: PayloadAction<ChatModel>) => {
            state.replies = [...state.replies].map((response) => {
                if (response.id === action.payload.id && response.type === action.payload.type) {
                    return action.payload;
                }
                return response;
            });
        },
        updateLastChatQuery: (state, action: PayloadAction<ChatModel>) => {
            state.replies = [...state.replies].map((response, index) => {
                if (index === state.replies.length - 1 && action.payload.type === "ai" && response.isLoading) {
                    return { ...response, ...action.payload };
                }
                return response;
            });
        },
        removeLastChatQuery: (state) => {
            state.replies = state.replies.slice(0, -1);
        },
        removeTypingQuery: (state) => {
            state.replies = state.replies.filter((response) => !response.isTyping);
        },
    },
});

export const { setChatLoadingStatus, addChatQuery, removeChatQuery, updateChatQuery, updateLastChatQuery, removeLastChatQuery, removeTypingQuery } = searchSlice.actions;

export default searchSlice.reducer;
