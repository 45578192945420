import { Button, FormControl, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast, useDisclosure } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { setMetaTags } from "../../utils/seo.utils";
import Select from "react-select";
import { useSelector } from "react-redux";
import { PersonaState } from "../../store/slices/persona.slice";
import { Field, Form, Formik } from "formik";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { IoCopyOutline } from "react-icons/io5";
import { shortenAPIKeyString } from "../../utils/strings.utils";
import { useHttpClient } from "../../utils/http.utils";
import hotToast from "react-hot-toast";
import { formatDate } from "../../utils/date.utils";

interface UserApiKey {
    label: string;
    id: string;
    key_string: string;
    createdAt: string;
    personas: [];
}

export const ApiKeysView: React.FC = () => {
    const apiKeysDisclosure = useDisclosure();
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const [userApiKeys, setUserApiKeys] = useState<UserApiKey[]>([]);
    const editApiKeysDisclosure = useDisclosure();
    const [currentApiKey, setCurrentApiKey] = useState<UserApiKey | null>(null);

    const toast = useToast();

    useEffect(() => {
        setMetaTags({ title: "API Keys" });
    }, []);

    const { createUserApiKeyWithPersona, listUserApiKey, deleteUserApiKey, editApiKeyWithPersona } = useHttpClient();

    const personaSelectOptions = useMemo(() => {
        const options = personaState.personas
            .map((persona) => {
                const value = persona?.id ?? "";
                const label = `${persona.first_name ?? ""} ${persona.last_name ?? ""}`.trim();
                const type = persona?.type ?? "";
                const category = "persona";
                return { value, label, type, category };
            })
            .filter((option) => option.value && option.label);

        return options;
    }, [personaState.personas]);

    const copyText = async (text: string) => {
        if (!navigator.clipboard) {
            hotToast.error("Clipboard API not available");
            return;
        }

        try {
            await navigator.clipboard.writeText(text);
            hotToast.success("Copied!");
            return text;
        } catch (err: any) {
            hotToast.error("Failed to read clipboard content:", err.toString());
        }
    };

    useEffect(() => {
        const displayUserApiKeys = async () => {
            const responseData = await listUserApiKey();

            if (responseData) {
                const userApiKeyData = responseData.map((key) => ({
                    id: key.id,
                    label: key.label,
                    key_string: key.key_string,
                    createdAt: key.createdAt,
                    personas: key.personas,
                }));

                setUserApiKeys(userApiKeyData);
            }
        };

        displayUserApiKeys();
    }, []);

    const onCreateAPIKey = async (values: { label: string; personas: string[] }, actions) => {
        try {
            const data = await createUserApiKeyWithPersona(values.label, values.personas);

            if (data) {
                setUserApiKeys((prev) => [...prev, { label: data.user_api_key.label, id: data.user_api_key.id, key_string: data.user_api_key.key_string, createdAt: data.user_api_key.createdAt, personas: data.user_api_key.personas }]);

                apiKeysDisclosure.onClose();

                toast({
                    title: "User API Key Created Successfully",
                    status: "success",
                });
            }
        } catch (error) {
            toast({
                title: `Error in creating User Api Key`,
                status: "error",
            });
            console.log("Error in onCreateAPIKey:", error);
        }

        actions.setSubmitting(false);
    };

    const deleteApiKey = async (keyId) => {
        try {
            const data = await deleteUserApiKey(keyId);

            if (data.success) {
                const updatedApiKeys = userApiKeys.filter((key) => key.id !== keyId);

                setUserApiKeys(updatedApiKeys);

                toast({
                    title: "User Api Key has been deleted!",
                    status: "success",
                });
            }
        } catch (error) {
            toast({
                title: `Error in deleting User Api Key`,
                status: "error",
            });

            console.log("Error in deleteApiKey:", error);
        }
    };

    const editApiKey = (keyId) => {
        setCurrentApiKey(null);
        const getEditKey = userApiKeys.find((apiKey) => apiKey.id === keyId);
        if (getEditKey) {
            setCurrentApiKey(getEditKey);
            editApiKeysDisclosure.onOpen();
        }
    };

    const updateApiKey = async (values: { keyId: string; label: string; personas: string[] }, actions) => {
        try {
            const data = await editApiKeyWithPersona(values.keyId, values.label, values.personas);
            if (data) {
                setUserApiKeys((prev) => prev.map((apiKey) => (apiKey.id === data.user_api_key.id ? { ...apiKey, ...data.user_api_key } : apiKey)));
                editApiKeysDisclosure.onClose();
                toast({
                    title: "User API Key Updated Successfully",
                    status: "success",
                });
            }
        } catch (error) {
            toast({
                title: `Error in updating User Api Key`,
                status: "error",
            });
            console.log("Error in editApiKeyWithPersona:", error);
        }
        actions.setSubmitting(false);
    };

    return (
        <div className="accounts-subview accounts-members h-full">
            <div className="side-nav-sm with-action">
                <div className="">
                    <h4 className="title">API Keys</h4>
                    <p className="subtitle">Create API Keys to be used by third-party applications.</p>
                </div>
                <div className="actions">
                    <Button onClick={() => apiKeysDisclosure.onOpen()} size="sm" leftIcon={<FiPlus />} colorScheme="brand">
                        Create API Key
                    </Button>
                </div>
            </div>

            <div className="account-body pt-4 pl-6 pr-6">
                <TableContainer>
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>Label</Th>
                                <Th>API Key</Th>
                                <Th>Created On</Th>
                                <Th>Status</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {userApiKeys && userApiKeys.length > 0 ? (
                                userApiKeys.map((item, index) => (
                                    <Tr key={index}>
                                        <Td>{item.label}</Td>
                                        <Td>
                                            <div className="flex justify-between items-center w-[200px]">
                                                <span>{shortenAPIKeyString(item.key_string, 20)}</span>
                                                <IconButton variant="ghost" onClick={() => copyText(item.key_string)} size={"sm"} aria-label="Copy" icon={<IoCopyOutline />} />
                                            </div>
                                        </Td>
                                        <Td>{formatDate(item.createdAt as string)}</Td>
                                        <Td>
                                            <p className="text-green-500 text-[12px] font-medium rounded-full px-3 py-1 w-auto inline-block bg-green-100">Active</p>
                                        </Td>
                                        <Td>
                                            <IconButton variant="ghost" aria-label="Delete" onClick={() => deleteApiKey(item.id)} icon={<MdOutlineDelete size={"24px"} className="text-red-500" />} />
                                            <IconButton variant="ghost" aria-label="Edit" onClick={() => editApiKey(item.id)} icon={<MdOutlineEdit size={"24px"} className="text-blue-500" />} />
                                        </Td>
                                    </Tr>
                                ))
                            ) : (
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
            {/* Modal to Create*/}
            <Modal isCentered isOpen={apiKeysDisclosure.isOpen} onClose={apiKeysDisclosure.onClose}>
                <ModalOverlay />
                <Formik initialValues={{ label: "", personas: [] }} onSubmit={onCreateAPIKey}>
                    {(props) => (
                        <Form>
                            <ModalContent>
                                <ModalHeader>Create API Key</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">API Key Label</p>
                                        <Field name="label" as={Input} placeholder="Enter Label" />
                                    </FormControl>

                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">Select personas</p>
                                        <Field name="personas">
                                            {({ field, form }) => (
                                                <Select
                                                    isMulti
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                    isClearable={false}
                                                    placeholder="Select personas"
                                                    name="personas"
                                                    value={personaSelectOptions.filter((option) => field.value.some((val) => val === option.value))}
                                                    onChange={(selectedOptions) => {
                                                        const modifiedData = (selectedOptions || []).map((option) => option.value);
                                                        form.setFieldValue("personas", modifiedData);
                                                    }}
                                                    onBlur={field.onBlur}
                                                    options={personaSelectOptions}
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button size="sm" colorScheme="brand" mr={3} type="submit" isLoading={props.isSubmitting} loadingText="Creating..">
                                        Create
                                    </Button>
                                    <Button size="sm" onClick={apiKeysDisclosure.onClose} variant="outline">
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    )}
                </Formik>
            </Modal>

            {/* Modal to Edit*/}
            <Modal isCentered isOpen={editApiKeysDisclosure.isOpen} onClose={editApiKeysDisclosure.onClose}>
                <ModalOverlay />
                <Formik
                    initialValues={{
                        keyId: currentApiKey ? currentApiKey.id : "",
                        label: currentApiKey ? currentApiKey.label : "",
                        personas: currentApiKey ? currentApiKey.personas : [],
                    }}
                    onSubmit={updateApiKey}
                >
                    {(props) => (
                        <Form>
                            <ModalContent>
                                <ModalHeader>Edit API Key</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">API Key Label</p>
                                        <Field name="label" as={Input} placeholder="Enter Label" />
                                    </FormControl>
                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">Select personas</p>
                                        <Field name="personas">
                                            {({ field, form }) => (
                                                <Select
                                                    isMulti
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                    isClearable={false}
                                                    placeholder="Select personas"
                                                    name="personas"
                                                    value={personaSelectOptions.filter((option) => field.value.some((val) => val === option.value))}
                                                    onChange={(selectedOptions) => {
                                                        const modifiedData = (selectedOptions || []).map((option) => option.value);
                                                        form.setFieldValue("personas", modifiedData);
                                                    }}
                                                    onBlur={field.onBlur}
                                                    options={personaSelectOptions}
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                </ModalBody>
                                <ModalFooter>
                                    <Button size="sm" colorScheme="brand" mr={3} type="submit" isLoading={props.isSubmitting} loadingText="Creating..">
                                        Edit
                                    </Button>
                                    <Button size="sm" onClick={editApiKeysDisclosure.onClose} variant="outline">
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default ApiKeysView;
