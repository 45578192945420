import { Button, ButtonGroup, Spinner, useToast } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import { BsMic } from "react-icons/bs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMetaTags } from "../../utils/seo.utils";
import "../../styles/IdeationView.component.scss";
import { useHttpClient } from "../../utils/http.utils";
import { CollaborateState, setEnableCollaborationInput, setIsCollaborateLoading } from "../../store/slices/collaborate.slice";
import { GrPlayFill } from "react-icons/gr";
import { scrollToBottom } from "../../utils/dom.utils";
import InteractionMessage from "../../components/collaborate/InteractionMessage";
import { PersonaModel } from "../../models/persona.model";
import { ProfileAvatar } from "../../components/Avatar";
import { AccountState } from "../../store/slices/account.slice";
import SetUpGeneralEntityAI from "../../components/modals/SetUpGeneralEntityModal";
import { getPersonaFullName } from "../../utils/personas.utils";
import LoadingDots from "../../components/LoadingDots";
import InteractiveInput from "../../components/interactiveInput";
import hotToast from "react-hot-toast";
import { AutoInteractionMessage } from "../../models/collaboration.model";

export default function IdeationView() {
    const searches = useSelector((state) => (state as any).searches);
    const personaState = useSelector((state: any) => state.persona);
    const toast = useToast();
    const searchBoxRef = useRef(null);
    const collaborateState: CollaborateState = useSelector((state) => (state as any).collaborate);
    const [selectedPersona, setSelectedPersona] = useState<PersonaModel>();
    const autoMessageContainerRef = useRef(null);
    const [displayUserInputs, setDisplayUserInputs] = useState<boolean>(true);
    const [recentUserInteraction, setRecentUserInteraction] = useState<AutoInteractionMessage | null>();
    const [personaOptions, setPersonaOptions] = useState<{ label?: string; value?: string }[]>([]);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const [isAISetUpModalOpen, setIsAISetUpModalOpen] = useState(false);

    const { createAutoInteractionMessageApi, initializeCollaborationCommunicationApi, sendExecutionMessageApi, executeCollaborationAppApi, executeToolWithInputsApi } = useHttpClient();
    const dispatch = useDispatch();

    useEffect(() => {
        setMetaTags({ title: "Applications | Ideation" });
        // if (collaborate_state.autoInteractionMessages.length === 0) {
        //     getInitialCollaborationMessageApi();
        // }
        // Fetch persona
        // fetchPersonaApi({}, {})
        //     .then((personas: any) => {
        //         setPersonas(personas);
        //     });
    }, []);

    useEffect(() => {
        // console.log(personaState);
        const generalAIEntity: PersonaModel = personaState.generalAIEntity;
        const generalEntityOptions: any = [];

        if (generalAIEntity.id) {
            generalEntityOptions.push({
                label: generalAIEntity.first_name + " " + generalAIEntity.last_name,
                value: generalAIEntity.id,
            });
            setSelectedPersona(generalAIEntity);
            setIsAISetUpModalOpen(false);
        } else {
            // Show pop to create AI Entity
            setIsAISetUpModalOpen(true);
        }
        setPersonaOptions(generalEntityOptions);
    }, [personaState.personas.length]);

    useEffect(() => {
        scrollToBottom(autoMessageContainerRef);
        // Disable input if the last message is a decision
        if (collaborateState.autoInteractionMessages.length > 0) {
            const lastMessage = collaborateState.autoInteractionMessages[collaborateState.autoInteractionMessages.length - 1];
            if (lastMessage.message_format === "decision") {
                dispatch(setEnableCollaborationInput(false));
            } else {
                dispatch(setEnableCollaborationInput(true));
            }
        }

        // Toogle displayuserInputs
        if (collaborateState.autoInteractionMessages.length > 0) {
            setDisplayUserInputs(true);
            getLastUserInputInteraction();
        }
    }, [collaborateState.autoInteractionMessages.length]);

    function onSearch(e: any) {
        const search = e.target.value;

        if (e.keyCode === 13) {
            if (!collaborateState.isInitialized) {
                toast({
                    title: "You will have to first initialize the session",
                    status: "warning",
                });
                return;
            }

            /**
             *  Two modes
             *  When creating a collaboration app, in this case, we will have to send the message to the AI directly thorught
             *  the createAutoInteractionMessageApi
             *  In the case of a collaboration app, we need to use
             *
             */

            if (collaborateState.viewMode === "initialize" && collaborateState.selectedCollaborationApp?.id) {
                sendExecutionMessageApi(collaborateState.selectedCollaborationApp.id, search);
            } else {
                createAutoInteractionMessageApi(collaborateState.autoInteractionId, search);
            }
            // Show loading spinner
            dispatch(setIsCollaborateLoading(true));
            // @ts-ignore
            searchBoxRef.current.value = "";
        }
    }

    function initCollaborate() {
        if (!selectedPersona) {
            toast({
                title: "Please select a persona",
                status: "warning",
            });
            return;
        }
        if (collaborateState.isInitialized) {
            return;
        }
        /* *
         * Get initial message from the AI
         */
        initializeCollaborationCommunicationApi(selectedPersona);
    }

    function executeApp() {
        if (collaborateState.selectedCollaborationApp?.id) {
            executeCollaborationAppApi(collaborateState.selectedCollaborationApp?.id);
        }
    }

    function getLastUserInputInteraction() {
        const reversedArray = [...collaborateState.autoInteractionMessages].reverse();
        const recentInteraction = reversedArray.find((element) => element.message_type === "ai" && element.message_format == "input");
        setRecentUserInteraction(recentInteraction);
    }

    async function onUserInputSubmit(responses) {
        try {
            const res = await executeToolWithInputsApi(responses);
            if (res.success) {
                setDisplayUserInputs(false);
                setRecentUserInteraction(null);
            } else {
                hotToast.error("Error Uploading file. Please try again.");
            }
        } catch (error) {
            hotToast.error("Error Uploading file. Please try again.");
        }
    }

    const onPersonaSelect = (option) => {
        const persona = personaState.personas.find((p) => p.id === option["value"]);
        if (persona) {
            setSelectedPersona(persona);
        }
    };

    const getPersonaName = (id: string | undefined) => {
        const persona = personaState.personas.find((p) => p.id === id);
        if (persona) {
            return persona.first_name + " " + persona.last_name;
        } else {
            return "";
        }
    };

    return (
        <div className={`ideation-view ${collaborateState.viewMode === "initialize" ? "without-search-box" : ""}`}>
            <div className="toolbar px-8 mb-0 flex gap-2 mr-1 justify-between items-center">
                <div className="flex">
                    <div className="flex">
                        <div className="twin-avatars" style={{ gap: "" }}>
                            <img src={"/user_avatar.png"} alt="avatar" className="profile-image" />
                            <ProfileAvatar size="35px" />
                        </div>
                    </div>
                    <div className="pl-3">
                        <div className="flex items-center h-full">
                            {collaborateState.viewMode === "initialize" ? (
                                // <p className="">
                                //     {getPersonaName(collaborateState.selectedCollaborationApp?.persona)} with {authUser.fullname || authUser.username}
                                // </p>
                                <p className="font-medium">
                                    <span className="general-entity-name">{getPersonaFullName(personaState.generalAIEntity)}</span> with {accountState.accountData?.username}
                                </p>
                            ) : (
                                <div className="flex gap-2 items-center h-full">
                                    {/* <Select placeholder="Select Persona" options={personaOptions} isDisabled={collaborateState.isInitialized} className="arin-react-select-container persona-select" classNamePrefix="arin-react-select" onChange={onPersonaSelect} value={personaOptions[0]}/> */}
                                    <p className="">{personaOptions[0]?.label}</p>
                                    {selectedPersona && <p className="text-slate-500 text-sm">with {accountState.accountData?.username}</p>}
                                </div>
                            )}
                        </div>
                        {/*<p className="arin-name text text-sm">Open Ideation collaboration</p>*/}
                        {/*{searches.character.character &&*/}
                        {/*    <p className="text-slate-500 text-xs">With {searches.character.character} & {authUser.fullname || authUser.username}</p>}*/}
                        {/*{searches.isCharacterLoading &&*/}
                        {/*    <p className="text-slate-500 flex gap-1 text-xs"><Spinner size="xs"/>Loading*/}
                        {/*        Character</p>}*/}
                        {/*{searches.characterLoadingError &&*/}
                        {/*    <p className="text-red-500 text-xs">Cannot fetch character</p>}*/}
                    </div>
                </div>
                <div className="actions">
                    <ButtonGroup>
                        {collaborateState.viewMode === "initialize" && (
                            <Button size="sm" variant="outline">
                                Auto
                            </Button>
                        )}
                        {collaborateState.viewMode === "initialize" && (
                            <Button size="sm" variant="outline" leftIcon={<FiPlus />}>
                                Invite
                            </Button>
                        )}
                        {collaborateState.viewMode === "initialize" ? (
                            <Button
                                onClick={executeApp}
                                isLoading={collaborateState.isExecuting}
                                size="sm"
                                // isDisabled={disableInitializeBtn}
                                variant={collaborateState.isExecuted ? "solid" : "outline"}
                                colorScheme={collaborateState.isExecuted ? "brand" : "gray"}
                            >
                                {collaborateState.isExecuting ? "Starting.." : collaborateState.isExecuted ? "Started" : "Start"}
                            </Button>
                        ) : (
                            <Button
                                onClick={initCollaborate}
                                isLoading={collaborateState.isInitializing}
                                size="sm"
                                // isDisabled={disableInitializeBtn}
                                variant={collaborateState.isInitialized ? "solid" : "outline"}
                                colorScheme={collaborateState.isInitialized ? "brand" : "gray"}
                            >
                                {collaborateState.isInitializing ? "Starting.." : collaborateState.isInitialized ? "Started" : "Start"}
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
            {/*<div className="about-card px-8 pt-4 pb-6">*/}
            {/*    <p className="text-slate-500 text-sm">Set the brain type and memories you want your arin agent to utilize</p>*/}
            {/*</div>*/}
            <div ref={autoMessageContainerRef} className="search-view pt-2">
                {collaborateState.autoInteractionMessages.length === 0 && !collaborateState.isExecuted && collaborateState.viewMode === "initialize" && (
                    <div className="empty-collaborate-message">
                        <GrPlayFill className={"icon mx-auto"} />
                        <p className="text-center mt-6">Click start to execute the app.</p>
                    </div>
                )}
                {collaborateState.autoInteractionMessages.length === 0 && !collaborateState.isInitialized && !collaborateState.isExecuted && collaborateState.viewMode === "start" && (
                    <div className="empty-collaborate-message">
                        <GrPlayFill className={"icon mx-auto"} />
                        <p className="text-center mt-6">
                            Click start to begin the cellular <br /> reasoning process.
                        </p>
                    </div>
                )}
                {collaborateState.autoInteractionMessages.map((message, index) => {
                    return <InteractionMessage key={index} message={message} />;
                })}
                {collaborateState.isCreatingApp && (
                    <div className="flex items-center my-4 gap-2 px-6 text-gray-600">
                        <Spinner size="sm" />
                        <p className="font-medium">Please wait while we build your application. This might take a few minutes..</p>
                    </div>
                )}
            </div>
            <div className="search-box-form">
                {collaborateState.appMode === "interactive" && collaborateState.viewMode !== "initialize" ? (
                    <div className="applications-searchbox">
                        {collaborateState.isLoading && (
                            <span className="spinner-container">
                                <Spinner size="sm" />
                            </span>
                        )}
                        <input ref={searchBoxRef} placeholder="Your Input" disabled={!collaborateState.enableInput || collaborateState.isLoading || collaborateState.lastDecisionMessageId !== ""} onKeyUp={onSearch} />
                        {/* <div className="actions">
                            <button className="action-btn">
                                <MdAttachFile className="icon" />
                            </button>
                            <button className="action-btn">
                                <BsMic className="icon" />
                            </button>
                        </div> */}
                    </div>
                ) : (
                    <div className="applications-searchbox-view-mode px-4 py-[10px]">
                        {collaborateState.isLoading && <LoadingDots size={8} text={"Executing command, Please wait.."} />}
                        {/* {
                                (collaborateState.isLoading === false && recentUserInteraction && displayUserInputs) && (
                                    // eslint-disable-next-line array-callback-return
                                    <InteractiveInput
                                        key={collaborateState.selectedCollaborationApp?.id}
                                        collaborationAppId={collaborateState.selectedCollaborationApp?.id as string}
                                        toolIdentifier={recentUserInteraction?.raw_content?.tool_identifier}
                                        _userInputQuestions={recentUserInteraction?.raw_content?.user_inputs}
                                        onUserInputSubmit={onUserInputSubmit}
                                    />
                                )
                            } */}
                    </div>
                )}
            </div>
            {/* Modals */}
            <SetUpGeneralEntityAI isOpen={isAISetUpModalOpen} onClose={() => setIsAISetUpModalOpen(false)} />
        </div>
    );
}
