import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchConfig, SearchModel } from "../../models/search.model";
import { CharacterModel } from "../../models/character.model";
import { useSelector } from "react-redux";
import { PromptModel } from "../../models/prompt.model";
import { MemoriesModelQuery } from "../../models/memories.model";
import { PersonaModel } from "../../models/persona.model";

interface MemoryConfiguration {
    check_list?: string; // "All" or "Segragated"
    rlhf_switch?: boolean;
    sub_query?: boolean;
    search_notes?: boolean;
    use_gpt4_db_query?: boolean;
    segregated_destribution?: string[]; // ["All"] when you need to select all the segregated namespaces also you will pass "check_list" : "Segragated"
    third_party_destribution?: string[];
}

export interface SearchState {
    isSearchLoading: boolean;
    isCharacterLoading: boolean;
    history: string;
    prompts: PromptModel[];
    hasPromptsLoaded: boolean;
    chain: SearchModel[]; // Responses that have been added to chain
    folderChain: SearchModel[]; // Responses that have been added to chain
    folderHistory: string;
    character: CharacterModel;
    persona: PersonaModel | null;
    replies: SearchModel[];
    characterLoadingError: boolean;
    isAllMemoriesSelected: boolean;
    useRLHFSearch: boolean;
    shouldSearchNotes: boolean;
    segregatedMemories: MemoriesModelQuery[];
    thirdPartyMemories: MemoriesModelQuery[];
    nucleusSettings: SearchConfig;
}

const initialState: SearchState = {
    isSearchLoading: false,
    isCharacterLoading: true,
    characterLoadingError: false,
    history: "",
    chain: [],
    folderChain: [],
    folderHistory: "",
    prompts: [],
    hasPromptsLoaded: false,
    persona: null,
    character: {
        character: "",
        character_message: "",
    },
    replies: [],
    isAllMemoriesSelected: true,
    useRLHFSearch: false,
    shouldSearchNotes: false,
    segregatedMemories: [],
    thirdPartyMemories: [],
    nucleusSettings: {
        temp: 0.8,
        custom_prompt: "",
        prompt_used: "",
        relation: "",
        alpha: 1,
        is_structured: false,
        max_tokens: 500,
        top_p: 0.6,
        frequency_penalty: 0.11,
        presence_penalty: 0.24,
        top_k: 4,
        num_beam: 4,
        model: "O",
        data_upload_id: "",
    },
};

const searchesSlice = createSlice({
    name: "searches",
    initialState,
    reducers: {
        setCharacter: (state, action: PayloadAction<CharacterModel>) => {
            state.character = action.payload;
        },
        setSearchLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isSearchLoading = action.payload;
        },
        setCharacterLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isCharacterLoading = action.payload;
        },
        setCharacterLoadingError: (state, action: PayloadAction<boolean>) => {
            state.characterLoadingError = action.payload;
        },
        addSearchQuery: (state, action) => {
            state.replies = [...state.replies, action.payload];
        },
        updateLastQuery: (state, action) => {
            state.replies = [...state.replies].map((response, index) => {
                if (index === state.replies.length - 1 && response.question === action.payload.question) {
                    return { ...response, ...action.payload };
                }
                return response;
            });
        },
        removeLastQuery: (state) => {
            state.replies = state.replies.slice(0, -1);
        },
        updateSearchQuery: (state, action) => {
            state.replies = [...state.replies].map((response) => {
                if (response.id === action.payload.id) {
                    return action.payload;
                }
                return response;
            });
        },
        // Chains
        addQueryToChain: (state, action: PayloadAction<SearchModel>) => {
            // If query exist in chain, exit
            const index = state.chain.findIndex((item) => item.id === action.payload.id);
            if (index < 0) {
                state.chain = [...state.chain, action.payload];
            }
        },
        setChain: (state, action: PayloadAction<SearchModel[]>) => {
            state.chain = action.payload;
        },
        removeFromChain: (state, action: PayloadAction<number>) => {
            state.chain = state.chain.filter((item, index) => item.id !== action.payload);
        },
        clearChain: (state) => {
            state.chain = [];
        },
        // Folder chain
        addQueryToFolderChain: (state, action: PayloadAction<SearchModel>) => {
            // If query exist in chain, exit
            const index = state.folderChain.findIndex((item) => item.id === action.payload.id);
            if (index < 0) {
                state.folderChain = [...state.folderChain, action.payload];
            }
        },
        setFolderChain: (state, action: PayloadAction<SearchModel[]>) => {
            state.folderChain = action.payload;
        },
        removeFromFolderChain: (state, action: PayloadAction<number>) => {
            state.folderChain = state.folderChain.filter((item, index) => item.id !== action.payload);
        },
        clearFolderChain: (state) => {
            state.folderChain = [];
        },
        // --- end
        setHistory: (state, action: PayloadAction<string>) => {
            state.history = action.payload;
        },
        clearHistory: (state) => {
            state.history = "";
        },
        // folder chain history
        setFolderHistory: (state, action: PayloadAction<string>) => {
            state.folderHistory = action.payload;
        },
        clearFolderHistory: (state) => {
            state.folderHistory = "";
        },
        setPrompts: (state, action: PayloadAction<PromptModel[]>) => {
            state.prompts = action.payload;
        },
        setIsAllMemoriesSelected: (state, action: PayloadAction<boolean>) => {
            state.isAllMemoriesSelected = action.payload;
        },
        setUseRLHFSearch: (state, action: PayloadAction<boolean>) => {
            state.useRLHFSearch = action.payload;
        },
        setShouldSearchNotes: (state, action: PayloadAction<boolean>) => {
            state.shouldSearchNotes = action.payload;
        },
        setSegregatedMemories: (state, action: PayloadAction<MemoriesModelQuery[]>) => {
            state.segregatedMemories = action.payload;
        },
        setThirdPartyMemories: (state, action: PayloadAction<MemoriesModelQuery[]>) => {
            state.thirdPartyMemories = action.payload;
        },
        checkSegregatedMemory(state, action: PayloadAction<{ id: string; checked: boolean }>) {
            state.segregatedMemories = state.segregatedMemories.map((memory) => {
                if (memory.id === action.payload.id) {
                    memory.isSelected = action.payload.checked;
                }
                return memory;
            });
        },
        checkThirdPartyMemory(state, action: PayloadAction<{ id: string; checked: boolean }>) {
            state.thirdPartyMemories = state.thirdPartyMemories.map((memory) => {
                if (memory.id === action.payload.id) {
                    memory.isSelected = action.payload.checked;
                }
                return memory;
            });
        },
        checkAllSegregatedMemories(state) {
            state.segregatedMemories = state.segregatedMemories.map((memory) => {
                memory.isSelected = true;
                return memory;
            });
        },
        uncheckAllSegregatedMemories(state) {
            state.segregatedMemories = state.segregatedMemories.map((memory) => {
                memory.isSelected = false;
                return memory;
            });

            state.isAllMemoriesSelected = false;
        },
        setHasPromptsLoaded(state, action: PayloadAction<boolean>) {
            state.hasPromptsLoaded = action.payload;
        },
        setFindPersona(state, action: PayloadAction<PersonaModel>) {
            state.persona = action.payload;
        },
        setNucleusSettings(state, action: PayloadAction<SearchConfig>) {
            state.nucleusSettings = { ...state.nucleusSettings, ...action.payload };
        },
    },
});

// Export actions
export const {
    addSearchQuery,
    updateSearchQuery,
    setCharacterLoadingStatus,
    setSearchLoadingStatus,
    setCharacterLoadingError,
    setShouldSearchNotes,
    setIsAllMemoriesSelected,
    setUseRLHFSearch,
    setCharacter,
    removeLastQuery,
    updateLastQuery,
    removeFromChain,
    addQueryToChain,
    setChain,
    addQueryToFolderChain,
    setFolderChain,
    removeFromFolderChain,
    clearFolderChain,
    setFolderHistory,
    clearFolderHistory,
    setPrompts,
    clearChain,
    setHistory,
    clearHistory,
    setSegregatedMemories,
    setThirdPartyMemories,
    checkSegregatedMemory,
    checkThirdPartyMemory,
    checkAllSegregatedMemories,
    uncheckAllSegregatedMemories,
    setHasPromptsLoaded,
    setFindPersona,
    setNucleusSettings,
} = searchesSlice.actions;
export default searchesSlice.reducer;
