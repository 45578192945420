import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FolderModel } from "../../models/folder.model";
import { SearchModel } from "../../models/search.model";

interface FolderSlice {
    loading: boolean;
    isSearching: boolean;
    activeFolderName: string;
    activeFolderHistory: string;
    folders: FolderModel[];
    activeFolderFiles: SearchModel[];
}

const initialState: FolderSlice = {
    loading: false,
    isSearching: false,
    activeFolderName: "",
    activeFolderHistory: "",
    folders: [],
    activeFolderFiles: [],
};

const folderSlice = createSlice({
    name: "folder",
    initialState,
    reducers: {
        setFolderLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setActiveFolder: (state, action: PayloadAction<string>) => {
            state.activeFolderName = action.payload;
        },
        populateFolders: (state, action: PayloadAction<FolderModel[]>) => {
            // remove folders with empty names
            if (action.payload.length > 0) {
                const folders = action.payload.filter((folder: FolderModel) => !!folder.name);
                state.folders = [...folders];
            }
        },
        addFolder: (state, action) => {
            // add folder if not exist
            const folderIndex = state.folders.findIndex((f) => f.name === action.payload.name);
            if (folderIndex > -1) return;
            state.folders = [...state.folders, action.payload];
        },
        setActiveFolderHistory(state, action: PayloadAction<string>) {
            state.activeFolderHistory = action.payload;
        },
        populateActiveFolderFiles: (state, action) => {
            state.activeFolderFiles = [...action.payload];
        },
        addActiveFolderQuery: (state, action) => {
            state.activeFolderFiles = [...state.activeFolderFiles, action.payload];
        },
        updateLastActiveFolderQuery: (state, action) => {
            state.activeFolderFiles = [...state.activeFolderFiles].map((response, index) => {
                if (index === state.activeFolderFiles.length - 1 && response.question === action.payload.question) {
                    return { ...response, ...action.payload };
                }
                return response;
            });
        },
        removeLastActiveFolderQuery: (state) => {
            state.activeFolderFiles = state.activeFolderFiles.slice(0, -1);
        },
        updateActiveFolderQuery: (state, action: PayloadAction<SearchModel>) => {
            state.activeFolderFiles = [...state.activeFolderFiles].map((response) => {
                if (response.id === action.payload.id) {
                    return action.payload;
                }
                return response;
            });
        },
    },
});

// Export actions
export const { addActiveFolderQuery, addFolder, populateFolders, populateActiveFolderFiles, setActiveFolder, setActiveFolderHistory, setFolderLoading, updateLastActiveFolderQuery, removeLastActiveFolderQuery, updateActiveFolderQuery } = folderSlice.actions;

export default folderSlice.reducer;
