import { useEffect, useState } from "react";
import { setMetaTags } from "../../../utils/seo.utils";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import Select from "react-select";
import { useHttpClient } from "../../../utils/http.utils";
import { Field, Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DeleteConfirmation } from "../../../components/utils/ConfirmationModals";
import hotToast from "react-hot-toast";
import { AccountState, setUserRole } from "../../../store/slices/account.slice";
import { useNavigate } from "react-router-dom";
import { getUserName } from "../../../utils/user.utils";

export default function UserRolesView() {
    const inviteUserDisclosure = useDisclosure();
    const [organizationMembers, setOrganizationMembers] = useState<any[]>([]);
    const toast = useToast();
    const { accountData: loggedInUser, activeOrganization }: AccountState = useSelector((state: any) => state.account);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { listOrganizationMemberApi, createOrganizationMemberApi, updateOrganizationMemberApi, deleteOrganizationMemberApi } = useHttpClient();

    useEffect(() => {
        setMetaTags({ title: "Organization Members" });
        // Fetch default organization
        loadOrganizationMembers();
    }, [activeOrganization]);

    function loadOrganizationMembers() {
        // fetch member
        if (Array.isArray(activeOrganization?.members)) {
            // @ts-ignore
            setOrganizationMembers(activeOrganization.members as any[]);
        } else {
            listOrganizationMemberApi(activeOrganization?.id as string)
                .then((data) => {
                    setOrganizationMembers(data);
                })
                .catch((err) => {
                    console.log(err.toString);
                    hotToast.error("Failed to fetch organization members");
                });
        }
    }

    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const closeFormModal = () => {
        inviteUserDisclosure.onClose();
    };

    const onInviteUser = (values, actions) => {
        /**
         * Reject if the re is organization is not set
         */
        if (!activeOrganization?.id) {
            hotToast.error("Please set up your organization before you add a member");
            return;
        }
        // check if the user is in the invited member user
        // Get form payload
        const payload = {
            email: values.email,
            organization_id: activeOrganization?.id || "",
            status: "invited",
            role: values.role,
        };

        createOrganizationMemberApi(payload)
            .then((res) => {
                toast({
                    title: "Success",
                    description: "User invited successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                actions.resetForm();
                closeFormModal();
                // fetch new memebers
                loadOrganizationMembers();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    };

    const options = [
        { value: "admin", label: "Administrator" },
        { value: "dbs", label: "Digital Brain Surgeon" },
        { value: "persona_user", label: "Persona User" },
    ];

    const onDeleteRoomMember = (id: string) => {
        const organizationMemberIndex = organizationMembers.findIndex((member) => member.id === id);

        // show confirmation modal
        DeleteConfirmation({
            title: "Delete Member",
            message: "Are you sure you want to delete this user?",
        }).then((isConfirmed) => {
            // delete user
            if (isConfirmed) {
                // remove organization member from list
                const newOrganizationMembers = [...organizationMembers];
                newOrganizationMembers.splice(organizationMemberIndex, 1);
                setOrganizationMembers(newOrganizationMembers);
                // Call api to delete user
                deleteOrganizationMemberApi(id).catch((err) => {
                    console.log(err);
                    setOrganizationMembers([...organizationMembers, organizationMembers[organizationMemberIndex]]);
                });
            }
        });
    };

    const updateRoomMember = async (id: string, role: "admin" | "dbs" | "persona_user") => {
        try {
            const organizationMemberIndex = organizationMembers.findIndex((member) => member.id === id);

            // if role changed update user otherwise exit function
            if (organizationMembers[organizationMemberIndex].role === role) {
                return;
            }

            await updateOrganizationMemberApi(id, role);

            // update user
            const newOrganizationMembers = [...organizationMembers];
            newOrganizationMembers[organizationMemberIndex] = {
                ...newOrganizationMembers[organizationMemberIndex],
                role,
            };
            setOrganizationMembers(newOrganizationMembers);
            // if the user is the currently signed user update role
            if (organizationMembers[organizationMemberIndex].user.id === loggedInUser?.id) {
                dispatch(setUserRole(role));
            }
        } catch (e) {
            hotToast.error("Cannot update the organization member at this time");
        }
    };

    return (
        <div className="accounts-subview accounts-members h-full">
            <div className="side-nav-sm with-action">
                <h4 className="title">Members</h4>
                <div className="actions">
                    {activeOrganization?.id && (
                        <Button onClick={() => inviteUserDisclosure.onOpen()} size="sm" leftIcon={<FiPlus />} colorScheme="brand">
                            Invite
                        </Button>
                    )}
                </div>
            </div>

            <div className="account-body pt-4 pl-6 pr-6">
                {!activeOrganization?.id && (
                    <div className="empty-state">
                        <div className="empty-content">
                            <img src="/organization_member.png" alt="" className="h-[85px] mx-auto" />
                            <h2 className="title text-2xl font-bold mt-3">Set up your organization</h2>
                            <p className="text-slate-500 mt-3 mb-3">You need to set up your organization before you can invite users to your organization.</p>
                            <Button onClick={() => navigate("/account/subscription/organization")} colorScheme="brand">
                                Set up organization{" "}
                            </Button>
                        </div>
                    </div>
                )}
                {organizationMembers.map((member, index) => (
                    <div key={"members-" + index} className="user-card">
                        <div className="user-info">
                            <img src={member?.user?.profile_picture || "/user_avatar.png"} alt="profile" />
                            <div className="info inline-flex flex-col justify-center gap-0">
                                <p className="name m-0">{getUserName(member.user)}</p>
                                {member?.user?.email && <p className="email mt-[-10px] text-neutral-500">{member?.user?.email}</p>}
                            </div>
                        </div>
                        <div className="select">
                            <Select
                                isSearchable={false}
                                isDisabled={activeOrganization?.user?.id === member.user.id || loggedInUser?.role !== "admin"}
                                value={options.find((option) => option.value === member.role)}
                                options={options}
                                className="arin-react-select-container"
                                classNamePrefix="arin-react-select"
                                onChange={(selectedOption) => {
                                    updateRoomMember(member.id, (selectedOption as any)?.value);
                                }}
                            />
                        </div>
                        <div className="actions">
                            {activeOrganization?.user?.id !== member.user.id && member.user.id !== loggedInUser?.id && (
                                <Button onClick={() => onDeleteRoomMember(member.id)} size="sm" variant="solid" colorScheme="red">
                                    Remove
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {/*    Modals*/}
            <Modal isOpen={inviteUserDisclosure.isOpen} onClose={closeFormModal} closeOnOverlayClick={false} size="lg" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Invite users</ModalHeader>
                    <ModalCloseButton />
                    <Formik initialValues={{ email: "", role: "" }} onSubmit={onInviteUser}>
                        {(props) => (
                            <Form>
                                <ModalBody>
                                    <div className="invite-user-modal-form mb-6">
                                        <Field name="email" validate={(e) => validateRequired("Email", e)}>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                    <FormLabel className="text-xs">Email</FormLabel>
                                                    <Input {...field} placeholder="username@example.com" />
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="role" validate={(e) => validateRequired("Role", e)}>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.role && form.touched.role}>
                                                    <FormLabel className="text-xs">Role</FormLabel>
                                                    <Select
                                                        isSearchable={false}
                                                        value={options.find((option) => (field.value ? field.value.includes(option.value) : ""))}
                                                        onChange={(selectedOption) => {
                                                            form.setFieldValue("role", (selectedOption as any)?.value);
                                                        }}
                                                        onBlur={field.onBlur}
                                                        options={options}
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                    />
                                                    <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={closeFormModal}>
                                        Close
                                    </Button>
                                    <Button isLoading={props.isSubmitting} loadingText="Saving.." type="submit" colorScheme="brand">
                                        Save
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </div>
    );
}
