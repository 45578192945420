import {
    Button,
    FormControl,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    useDisclosure,
    Image,
    Box
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { PersonaState } from "../store/slices/persona.slice";
import { useHttpClient } from "../utils/http.utils";
import CreatableSelect from "react-select/creatable";

export const ReplyEmails: React.FC = () => {
    const apiKeysDisclosure = useDisclosure();
    const warningDisclosure = useDisclosure();
    const [formValues, setFormValues] = useState<{ label: string, personas: string[] } | null>(null);
    const personaState: PersonaState = useSelector((state: any) => state.persona);

    const toast = useToast();

    const { replyUnreadEmailApi } = useHttpClient();

    const personaSelectOptions = useMemo(() => {
        const options = personaState.personas
            .map((persona) => {
                const value = persona?.id ?? "";
                const label = `${persona.first_name ?? ""} ${persona.last_name ?? ""}`.trim();
                const type = persona?.type ?? "";
                const category = "persona";
                return { value, label, type, category };
            })
            .filter((option) => option.value && option.label);

        return options;
    }, [personaState.personas]);

    const replyUnreadEmail = async (values: { label: string, personas: string[] }, actions) => {
        if (!values.label) {
            setFormValues(values);
            warningDisclosure.onOpen();
            actions.setSubmitting(false);
            return;
        }

        try {
            const data = await replyUnreadEmailApi(values.personas, values.label);

            if (data?.success) {
                apiKeysDisclosure.onClose();

                toast({
                    title: "Unread emails replied successfully",
                    status: "success",
                });
            }
        } catch (error) {
            toast({
                title: `Error in creating replying emails`,
                status: "error",
            });
            console.log("Error in replyUnreadEmail:", error);
        }

        actions.setSubmitting(false);
    };

    const handleWarningConfirm = async () => {
        if (formValues) {
            try {
                const data = await replyUnreadEmailApi(
                    formValues.personas,
                    formValues.label
                )

                if (data?.success) {
                    apiKeysDisclosure.onClose()
                    warningDisclosure.onClose()

                    toast({
                        title: "Unread emails replied successfully",
                        status: "success",
                    });
                }
            } catch (error) {
                toast({
                    title: `Error in creating replying emails`,
                    status: "error",
                });
                console.log("Error in replyUnreadEmail:", error);
            }
        }
    };

    const validatePositiveWholeNumber = (value: string) => {
        if (value.trim() === "") {
            return undefined;
        }

        const number = Number(value);

        if (!Number.isInteger(number) || number <= 0) {
            return "Time duration must be a positive whole number";
        }
        return undefined;
    };

    return (
        <div className="accounts-subview accounts-members h-full">
            <div className="actions">
                <Button
                    onClick={() => apiKeysDisclosure.onOpen()}
                    size="sm"
                    // leftIcon={<FiPlus />}
                    colorScheme="brand"
                >
                    Reply To Emails
                </Button>
            </div>

            <Modal isCentered isOpen={apiKeysDisclosure.isOpen} onClose={apiKeysDisclosure.onClose}>
                <ModalOverlay />
                <Formik initialValues={{ label: "", personas: [] }} onSubmit={replyUnreadEmail}>
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <ModalContent>
                                <ModalHeader>Reply Unread Emails</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">Time Duration (minutes)</p>
                                        <Field name="label" validate={validatePositiveWholeNumber}>
                                            {({ field, form }) => (
                                                <>
                                                    <CreatableSelect
                                                        isClearable
                                                        isSearchable
                                                        name="label"
                                                        placeholder="Enter Time Duration"
                                                        value={{ value: field.value, label: field.value }}
                                                        options={[
                                                            { value: "30", label: "30" },
                                                            { value: "60", label: "60" },
                                                            { value: "120", label: "120" },
                                                            { value: "240", label: "240" },
                                                        ]}
                                                        onChange={(option: { value: string; label: string } | null) => {
                                                            const value = option ? option.value : "";
                                                            form.setFieldValue("label", value);
                                                            form.setFieldTouched("label", true, false);
                                                        }}
                                                        components={{
                                                            ClearIndicator: () => null,
                                                        }}
                                                    />
                                                    {errors.label && touched.label && <div className="text-red-500 text-sm mt-1">{errors.label}</div>}
                                                </>
                                            )}
                                        </Field>
                                    </FormControl>

                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">Select personas</p>
                                        <Field name="personas">
                                            {({ field, form }) => (
                                                <Select
                                                    isMulti
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                    isClearable={false}
                                                    placeholder="Select personas"
                                                    name="personas"
                                                    value={personaSelectOptions.filter((option) => field.value.some((val) => val === option.value))}
                                                    onChange={(selectedOptions) => {
                                                        const modifiedData = (selectedOptions || []).map((option) => option.value);
                                                        form.setFieldValue("personas", modifiedData);
                                                    }}
                                                    onBlur={field.onBlur}
                                                    options={personaSelectOptions}
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button size="sm" colorScheme="brand" mr={3} type="submit" isLoading={isSubmitting} loadingText="Replying...">
                                        Reply
                                    </Button>
                                    <Button size="sm" onClick={apiKeysDisclosure.onClose} variant="outline">
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal isCentered isOpen={warningDisclosure.isOpen} onClose={warningDisclosure.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader pb={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <Image src="https://assets.neoworlder.com/img/crisis.png" alt="Warning" boxSize="75px" />
                        </Box>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt={-5}>
                        <Box textAlign="center">
                            Are you sure? The action you are about to perform will be resource intensive.
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" colorScheme="brand" mr={3} onClick={handleWarningConfirm}>
                            Confirm
                        </Button>
                        <Button size="sm" onClick={warningDisclosure.onClose} variant="outline">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div >
    );
};

export default ReplyEmails;
