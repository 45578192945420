import { Button, ButtonGroup, FormControl, FormErrorMessage, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Formik, Field, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useHttpClient } from "../../utils/http.utils";
import { useSelector } from "react-redux";
import { PaymentMethodModel } from "../../models/payment.model";

const countryOptions = [
    {
        value: "us",
        label: "United States",
    },
];

interface PaymentMethodFormProps {
    isOpen: boolean;
    onClose: () => void;
}
export default function PaymentMethodForm(props: PaymentMethodFormProps) {
    const paymentMethodModal = useDisclosure();
    const { createPaymentMethodApi } = useHttpClient();

    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;

    const [isSaving, setIsSaving] = useState(false);
    const cardFormErrorRef = useRef(null);
    const [isCardFormReady, setIsCardFormReady] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (props.isOpen) {
            paymentMethodModal.onOpen();
        } else {
            paymentMethodModal.onClose();
        }
    }, [props.isOpen]);

    const closeFormModal = () => {
        paymentMethodModal.onClose();
        setIsCardFormReady(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    /**
     * Form validators
     */
    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const addPaymentMethod = async (values, actions) => {
        if (stripe && elements) {
            // set saving
            setIsSaving(true);
            // @ts-ignore
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
                billing_details: {
                    name: values.name,
                    email: values.email,
                    address: {
                        line1: values.address_line_1,
                        ...(values.address_line_2 && {
                            line2: values.address_line_2,
                        }),
                        city: values.city,
                        state: values.state,
                        country: values.country,
                    },
                },
            });
            // handle error and paymentMethod
            if (error) {
                console.log(error);
                (cardFormErrorRef.current as any).innerText = error.message;
                setIsSaving(false);
                return;
            }
            // Save data to database
            const payload = {
                card_extract: paymentMethod.card?.last4,
                card_brand: paymentMethod.card?.brand,
                type: paymentMethod.type,
                stripe_id: paymentMethod.id,
                expiry_date: paymentMethod.card?.exp_month + "/" + paymentMethod.card?.exp_year,
                /**
                 * If there are no configured payment methods, set the current payment method as the default
                 * Reason being that there must be at least one configured default payment method
                 */
                is_default: paymentMethods.length === 0 ? "yes" : "no",
            };
            await createPaymentMethodApi(payload);
            // close modal
            closeFormModal();
            // reset form
            actions.resetForm();
            actions.setIsSubmitting(false);
            setIsSaving(false);
        }
    };

    return (
        <Modal isOpen={paymentMethodModal.isOpen} onClose={closeFormModal} closeOnOverlayClick={!isSaving} size="md" isCentered>
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        country: "",
                        address_line_1: "",
                        address_line_2: "",
                        city: "",
                        state: "",
                    }}
                    onSubmit={addPaymentMethod}
                >
                    {(props) => (
                        <Form>
                            <ModalHeader>Add Payment Method</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div className="relative rounded-[8px]">
                                    {!isCardFormReady && (
                                        <div className="spinner absolute rounded-[8px] top-0 left-0 right-0 bottom-0 z-20 bg-white flex justify-center items-center">
                                            <div className="flex flex-col items-center">
                                                <Spinner size={"lg"} />
                                                <p className="mt-4 text-slate-400">Loading Payment method form</p>
                                            </div>
                                        </div>
                                    )}

                                    <p className="text-[15px] font-semibold mb-3">Card Information</p>

                                    <>
                                        {/* <CardElement /> */}
                                        <div>
                                            <div className="mb-3">
                                                <p className="text-[13px] font-medium mb-1">Card Details</p>
                                                <CardElement onReady={() => setIsCardFormReady(true)} />
                                                <p ref={cardFormErrorRef} className="mt-2 text-red-500 text-[13px]"></p>
                                            </div>
                                            {/* <div className="grid grid-cols-[56%_40%] gap-3">
                                                            <div>
                                                                <p className="text-[13px] font-medium mb-1">
                                                                    Expiry date
                                                                </p>
                                                                <CardExpiryElement />
                                                            </div>
                                                            <div>
                                                                <p className="text-[13px] font-medium mb-1">
                                                                    CVC
                                                                </p>
                                                                <CardCvcElement />
                                                            </div>
                                                        </div> */}
                                        </div>
                                    </>

                                    <div className="mt-[32px]">
                                        <p className="text-[15px] font-semibold mb-3">Billing Information</p>
                                        <Field name="name" validate={(e) => validateRequired("Name", e)}>
                                            {({ field, form }) => (
                                                <FormControl mb={2} isInvalid={form.errors.name && form.touched.name}>
                                                    <p className="text-[13px] font-medium mb-1">Name</p>
                                                    <Input {...field} placeholder="Name" size={"sm"} />
                                                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="email" validate={(e) => validateRequired("Email", e)}>
                                            {({ field, form }) => (
                                                <FormControl mb={3} isInvalid={form.errors.email && form.touched.email}>
                                                    <p className="text-[13px] font-medium mb-1">Email</p>
                                                    <Input {...field} placeholder="Email" size="sm" />
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="country" validate={(e) => validateRequired("Country", e)}>
                                            {({ field, form }) => (
                                                <FormControl mb={3} isInvalid={form.errors.country && form.touched.country}>
                                                    <p className="text-[13px] font-medium mb-1">Country</p>
                                                    <Select
                                                        placeholder="Select country"
                                                        value={countryOptions.find((option) => (field.value ? field.value.includes(option.value) : ""))}
                                                        onChange={(selectedOption) => {
                                                            form.setFieldValue("country", (selectedOption as any)?.value);
                                                        }}
                                                        onBlur={field.onBlur}
                                                        options={countryOptions}
                                                        className="arin-react-select-container"
                                                        classNamePrefix="arin-react-select"
                                                    />
                                                    <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="address_line_1" validate={(e) => validateRequired("Address", e)}>
                                            {({ field, form }) => (
                                                <FormControl mb={3} isInvalid={form.errors.address_line_1 && form.touched.address_line_1}>
                                                    <Input {...field} placeholder="Address line 1" size="sm" />
                                                    <FormErrorMessage>{form.errors.address_line_1}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Field name="address_line_2">
                                            {({ field, form }) => (
                                                <FormControl mb={3}>
                                                    <Input {...field} placeholder="Address line 2" size="sm" />
                                                </FormControl>
                                            )}
                                        </Field>

                                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                            <GridItem w="100%">
                                                <Field name="city" validate={(e) => validateRequired("City", e)}>
                                                    {({ field, form }) => (
                                                        <FormControl mb={3} isInvalid={form.errors.city && form.touched.city}>
                                                            <Input {...field} placeholder="City" size="sm" />
                                                            <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </GridItem>
                                            <GridItem w="100%">
                                                <Field name="state" validate={(e) => validateRequired("State", e)}>
                                                    {({ field, form }) => (
                                                        <FormControl mb={3} isInvalid={form.errors.state && form.touched.state}>
                                                            <Input {...field} placeholder="State" size="sm" />
                                                            <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </GridItem>
                                        </Grid>
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                {isCardFormReady && (
                                    <ButtonGroup spacing={3}>
                                        <Button variant="ghost" onClick={closeFormModal}>
                                            Close
                                        </Button>
                                        <Button isLoading={isSaving} loadingText="Saving.." type="submit" color="white" colorScheme="brand">
                                            Save
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
}
