import { PersonaModel } from "../../models/persona.model";
import { ProfileAvatar } from "../Avatar";
import "../../styles/personas/PersonaListCard.component.scss";
import { useEffect, useState } from "react";
import { GiDeadHead, GiNightSleep } from "react-icons/gi";
import { getPersonaProfilePicture } from "../../utils/personas.utils";
import { capitalizeWord } from "../../utils/strings.utils";

interface PersonaListCardProps {
    data: PersonaModel;
    isActive?: boolean;
    state?: "sleep" | "active" | "dead";
    onClick?: (persona: PersonaModel) => void;
}

export default function PersonaListCard(props: PersonaListCardProps) {
    const [personaStateClass, setPersonaStateClass] = useState<string>("");
    const [profilePicture, setProfilePicture] = useState<string>("");

    useEffect(() => {
        // create class depending on state
        switch (props.state) {
            case "sleep":
                setPersonaStateClass("bg-gray-50 text-yellow-900");
                break;
            case "active":
            default:
                setPersonaStateClass("");
                break;
            case "dead":
                setPersonaStateClass("border-gray-400 bg-gray-50 text-gray-900");
                break;
        }

        if (props.data.profile_photo) {
            setProfilePicture(props.data.profile_photo);
        } else {
            const url = getPersonaProfilePicture(props.data);
            setProfilePicture(url);
        }
    }, [props.state]);

    const handleClick = (data: PersonaModel) => {
        if (props.onClick) {
            props.onClick(props.data);
        }
    };

    return (
        <div className={`persona-list-card border border-gray-200 ${personaStateClass} ${props.isActive && props.state !== "dead" ? "active" : ""} mb-[12px] px-3 py-2 rounded-lg`} onClick={() => handleClick(props.data)}>
            <div className="list-card-contents">
                <div className="profile-section pt-[4px]">
                    <ProfileAvatar type="persona" src={profilePicture} size="30px" />
                </div>
                <div className="w-full overflow-hidden">
                    <h4 className="clone-text font-semibold truncate overflow-x-hidden w-full">{props.data.first_name + " " + props.data.last_name}</h4>
                    <p className="type text-[12px] text-zinc-400">{capitalizeWord(props.data.type || "")}</p>
                </div>
                <div className="actions pt-[4px]">
                    {props.state === "sleep" && <GiNightSleep className="text-yellow-600" size="18px" />}
                    {props.state === "dead" && <GiDeadHead className="text-gray-800" size="18px" />}
                </div>
            </div>
        </div>
    );
}
