"use client";
import { useEffect } from "react";
import Spinner from "../components/ui/spinner";
import { RxDoubleArrowRight } from "react-icons/rx";
import { useSelector } from "react-redux";
import { selectAuthState } from "../store/slices/auth.slice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";

export default function AppInitializerView() {
    const navigate = useNavigate();
    const authenticated = useSelector(selectAuthState);

    useEffect(() => {
        if (authenticated) {
            navigate(ROUTES.PERSONAS);
        }
    }, [authenticated, navigate]);

    return (
        <main className="min-h-screen flex flex-col items-center pt-64 min-w-screen px-10 ">
            <div className="row flex items-center bg-transparent">
                <div className="location bg-transparent">
                    <img src={"https://assets.neoworlder.com/img/neoworlder_logo_black.png"} alt={"@NeoWorlder"} className="dark:hidden rounded-[4px] w-auto h-[55px]" />
                    <img src={"https://assets.neoworlder.com/img/neoworlder_logo_white.png"} alt={"@NeoWorlder"} className="hidden dark:block rounded-[4px]  w-auto h-[55px]" />
                </div>
                <div className="arrow flex items-center mr-[12px]">
                    <RxDoubleArrowRight size={"30px"} />
                </div>
                <div className="destination">
                    <img src={"https://assets.neoworlder.com/img/v1/lifelab_logo_black.png"} alt={"@NeoWorlder"} className="dark:hidden rounded-[4px] w-auto h-[40px]" />
                    <img src={"https://assets.neoworlder.com/img/v1/lifelab_logo_white.png"} alt={"@NeoWorlder"} className="hidden dark:block rounded-[4px]  w-auto h-[40px]" />
                </div>
            </div>
            <div className="mt-4">
                <p className="text-base text-center text-neutral-700">You are leaving from NeoWorlder into your Organization's Lab</p>
            </div>
            <div className="loader flex gap-2 items-center mt-6 text-muted-foreground">
                <Spinner />
                <p>Please hold on while we are getting your Life Lab ready!</p>
            </div>
        </main>
    );
}
