import { Button, ButtonGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

export default function SetUpGeneralEntityAI(props: ModalProps) {
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setModalVisible(props.isOpen);
    }, [props.isOpen]);

    const close = () => {
        setModalVisible(false);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const goToSetUp = () => {
        navigate("/personas?view=general-entity");
    };

    return (
        <Modal isOpen={modalVisible} onClose={close} isCentered closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Set Up Your General Entity AI</ModalHeader>
                <ModalBody>
                    <img src="/no-ai-entity.png" alt="" className="h-[180px] block mx-auto" />
                    <p className="text-slate-800">Hello there! It looks like you're eager to dive into the Find and Collab. We're just as excited to see you use them! However, we've noticed that your General AI Entity isn't set up yet. These advanced features rely on the AI Entity to function effectively.</p>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup spacing={"2"}>
                        <Button size={"sm"} onClick={goToSetUp} variant="solid" colorScheme="brand">
                            Set Up AI Entity
                        </Button>
                        {/* <Button size={"sm"} onClick={close} variant="ghost">Close</Button> */}
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
