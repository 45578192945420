import { GiBrainStem } from "react-icons/gi";
import { AiOutlineInteraction } from "react-icons/ai";
import { SiObservable } from "react-icons/si";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { clearBrainActivityMessages, CollaborateState } from "../../store/slices/collaborate.slice";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/BrainActivity.component.scss";
import { GrPlan, GrStatusCritical, GrTools } from "react-icons/gr";
import { FaThinkPeaks } from "react-icons/fa";
import Markdown from "markdown-to-jsx";
import { formatCollabMarkDownMessage } from "../../utils/collaborate/parser";
import { scrollToBottom } from "../../utils/dom.utils";

export default function BrainActivityPanel() {
    const activityBodyRef = useRef<HTMLDivElement>(null);
    const collaborate_state: CollaborateState = useSelector((state) => (state as any).collaborate);
    const dispatch = useDispatch();

    /**
     * Listen for new messages and scroll to the bottom of the activity body and automatically scroll to the buttom
     * of the view
     */
    useEffect(() => {
        scrollToBottom(activityBodyRef);
    }, [collaborate_state.brainActivityMessages]);

    const clearAutoInteractionMessages = () => {
        dispatch(clearBrainActivityMessages());
    };

    const showLoading = () => {
        return collaborate_state.brainActivityMessages.length === 0;
    };

    const renderTextWithNewlines = (text: string) => {
        const lines = text.split("\n");

        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="activity-column h-full bg-white">
            <div className="side-nav-sm">
                <p className="title">Brain Activity</p>
                <p className="subtitle">Peek inside your persona's brain.</p>
            </div>
            <div ref={activityBodyRef} className="activity-body pt-2 px-6">
                {showLoading() && (
                    <div className="loading-animation">
                        <img src="/system-outline.gif" className="image" alt="system" />
                        <span className="text-sm">Neurons Firing</span>
                    </div>
                )}
                {collaborate_state.brainActivityMessages.length > 0 && (
                    <div className="cycle-container pt-3 bg-white mb-3">
                        {/*<div className="flex items-center gap-2">*/}
                        {/*    <p className="text-sm font-semibold">Cycle 1</p>*/}
                        {/*    <p className="text-slate-500 text-xs">{formatDate((new Date()).toISOString(), "LLL")}</p>*/}
                        {/*</div>*/}
                        <div className="pl-3 cycles">
                            {
                                collaborate_state.brainActivityMessages
                                    .map((message, index) => {
                                        let elements: JSX.Element[] = [];

                                        if (message.observation) {
                                            elements.push(
                                                <div key={"observation-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <SiObservable />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Observation</p>
                                                        {renderTextWithNewlines(message.observation)}
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.thought) {
                                            elements.push(
                                                <div key={"thought-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <GiBrainStem />
                                                    </p>
                                                    <p className="text pl-3">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Thought</p>
                                                        {}
                                                    </p>
                                                </div>,
                                            );
                                        }

                                        if (message.reasoning) {
                                            elements.push(
                                                <div key={"reasoning-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <FaThinkPeaks />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Thinking</p>
                                                        {renderTextWithNewlines(message.reasoning)}
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.plan) {
                                            elements.push(
                                                <div key={"plan-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <GrPlan />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Plan</p>
                                                        {renderTextWithNewlines(message.plan)}
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.action) {
                                            elements.push(
                                                <div key={"action-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <AiOutlineInteraction />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Action</p>
                                                        {renderTextWithNewlines(message.action)}
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.criticism) {
                                            elements.push(
                                                <div key={"criticism-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <GrStatusCritical />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Criticism</p>
                                                        {renderTextWithNewlines(message.criticism)}
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.tool_result) {
                                            elements.push(
                                                <div key={"tool-result-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <GrTools />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Tool</p>
                                                        {/* {renderTextWithNewlines(
                                                            message.tool_result,
                                                        )} */}
                                                        <div className="brain-activity-mdx leading-[170%]">
                                                            <Markdown options={{ forceBlock: true }}>{formatCollabMarkDownMessage(message.tool_result)}</Markdown>
                                                        </div>
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.content_log) {
                                            elements.push(
                                                <div key={"content-log-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <SiObservable />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Activity</p>
                                                        {message.content_log?.general_text && <div>{renderTextWithNewlines(message.content_log.general_text)}</div>}

                                                        {message.content_log?.action && (
                                                            <div>
                                                                {message.content_log.action}: {renderTextWithNewlines(message.content_log.action_input)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        if (message.evaluation) {
                                            elements.push(
                                                <div key={"content-log-" + index} className="cycle-card mt-4 pl-2">
                                                    <p className="icon">
                                                        <SiObservable />
                                                    </p>
                                                    <div className="text pl-2">
                                                        <p className="font-medium text-slate-500 text-sm mb-2">Evaluation</p>
                                                        <div>Comment: {message.evaluation.comment}</div>
                                                        <br />
                                                        <div>Commentary: {message.evaluation.commentary}</div>
                                                        <br />
                                                        <div>Tool Evaluation Score: {message.evaluation.tool_evaluation_score}</div>
                                                        <div>Goal Evaluation Score: {message.evaluation.goal_evaluation_score}</div>
                                                    </div>
                                                </div>,
                                            );
                                        }

                                        return elements;
                                    })
                                    .flat()
                                // collaborate_state.brainActivityMessages.map((message, index) => {
                                //     let el = <></>;
                                //     if (message.thought) {
                                //         el = (
                                //             <div key={"thought-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><GiBrainStem/></p>
                                //                 <p className="text pl-3">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Thought</p>
                                //                     {}
                                //                 </p>
                                //             </div>
                                //         )
                                //     }
                                //     if (message.action) {
                                //         el = (
                                //             <div key={"action-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><AiOutlineInteraction/></p>
                                //                 <div className="text pl-2">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Action</p>
                                //                     {message.action}
                                //                 </div>
                                //             </div>
                                //         )
                                //     }
                                //     if (message.observation) {
                                //         el = (
                                //             <div key={"observation-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><SiObservable/></p>
                                //                 <div className="text pl-2">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Observation</p>
                                //                     {message.observation}
                                //                 </div>
                                //             </div>
                                //         )
                                //     }
                                //     if (message.plan) {
                                //         el = (
                                //             <div key={"plan-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><GrPlan/></p>
                                //                 <div className="text pl-2">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Plan</p>
                                //                     {message.plan}
                                //                 </div>
                                //             </div>
                                //         )
                                //     }
                                //     if (message.reasoning) {
                                //         el = (
                                //             <div key={"reasoning-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><FaThinkPeaks/></p>
                                //                 <div className="text pl-2">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Reasoning</p>
                                //                     {message.reasoning}
                                //                 </div>
                                //             </div>
                                //         )
                                //     }
                                //     if (message.criticism) {
                                //         el = (
                                //             <div key={"criticism-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><GrStatusCritical /></p>
                                //                 <div className="text pl-2">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Criticism</p>
                                //                     {message.criticism}
                                //                 </div>
                                //             </div>
                                //         )
                                //     }
                                //     if (message.content_log) {
                                //         el = (
                                //             <div key={"content-log-" + index} className="cycle-card mt-4 pl-2">
                                //                 <p className="icon"><SiObservable/></p>
                                //                 <div className="text pl-2">
                                //                     <p className="font-medium text-slate-500 text-sm mb-2">Activity</p>
                                //                     {message.content_log?.general_text &&
                                //                         <div>{message.content_log.general_text}</div>}

                                //                     {message.content_log?.action &&
                                //                         <div>{message.content_log.action}: {message.content_log.action_input}</div>}
                                //                 </div>
                                //             </div>
                                //         )
                                //     }
                                //     return el;
                                // })
                            }
                            {/*<div className="cycle-card mt-4 pl-2">*/}
                            {/*    <p className="icon completed"><IoMdDoneAll className="text-white"/>*/}
                            {/*    </p>*/}
                            {/*    <div className="text pl-2">*/}
                            {/*        <p className="font-medium text-slate-500 text-sm mb-2">Task*/}
                            {/*            Completed</p>*/}
                            {/*        From my conclusion this is what we need to do.*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )}
            </div>
            <div className="activity-footer px-6 pt-1 border-t">
                {/* <Button variant="ghost" size="sm">
                    Stop
                </Button> */}
                {/*<Button variant="ghost" size="sm">Give Advice</Button>*/}
                <Button variant="ghost" colorScheme="red" onClick={clearAutoInteractionMessages} size="sm">
                    Clear
                </Button>
            </div>
        </div>
    );
}
