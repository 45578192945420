import { Button, IconButton, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Spinner, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import "../../styles/PersonasSideBar.component.scss";
import ReactSelect, { SingleValue } from "react-select";
import { TbRobot } from "react-icons/tb";
import { AiFillCheckCircle } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonaModel } from "../../models/persona.model";
import { PersonaState, setPersonaAppDisplayFilter } from "../../store/slices/persona.slice";
import PersonaListCard from "./PersonaListCard";
import { IoFilterOutline } from "react-icons/io5";
import { cloneDeep, debounce } from "lodash";
import { convertFiltersToSearchCriteria, useHttpClient } from "../../utils/http.utils";
import { capitalizeWord } from "../../utils/strings.utils";

interface PersonaSideBarProps {
    onPersonaSelect: (persona: PersonaModel) => void;
    onCreatePersona?: () => void;
}

export default function PersonaSideBar({ onPersonaSelect, onCreatePersona }: PersonaSideBarProps) {
    const personaState = useSelector((state: any) => state.persona) as PersonaState;

    // const [selectedArins, setSelectedArins] = useState([agents[0]]);
    const [selectedPersonaId, setSelectedPersonaId] = useState<string>("");
    const [isAiEntityActive, setIsAiEntityActive] = useState<boolean>(false);
    const [selectedPersonas, setSelectedPersonas] = useState<PersonaModel[]>([]);
    const [personaSelectOptions, setPersonaSelectOptions] = useState<{ label: string; value: string }[]>([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const { fetchPersonaApi } = useHttpClient();
    const dispatch = useDispatch();

    useEffect(() => {
        const options = personaState.personas.map((persona) => ({
            value: persona.id as string,
            label: (persona.first_name as string) + " " + (persona.last_name as string),
        }));
        setPersonaSelectOptions(options);
    }, [personaState.personas]);

    useEffect(() => {
        let searchQuery = convertFiltersToSearchCriteria(personaState.displayFilters);

        fetchPersonaApi(searchQuery);
    }, [personaState.displayFilters]);

    const onSelectPersonas = (values: any) => {
        const personas: PersonaModel[] = values.map((v) => {
            return personaState.personas.find((p) => p.id === v.value) as PersonaModel;
        });
        setSelectedPersonas(personas);
    };

    const createPersona = () => {
        setSelectedPersonaId("");
        setIsAiEntityActive(false);
        if (typeof onCreatePersona === "function") {
            onCreatePersona();
        }
    };

    const personaSelect = (persona: PersonaModel) => {
        setSelectedPersonaId(persona.id as string);
        setIsAiEntityActive(persona.is_general_entity === "yes");

        if (typeof onPersonaSelect === "function") {
            onPersonaSelect(persona);
        }
    };

    const onPersonaFilterChange = (key: string, data: SingleValue<{ label: string; value: string }>) => {
        // find filter
        const filtersCopy = cloneDeep(personaState.displayFilters);
        // find filter
        const filterIndex = filtersCopy.findIndex((filter) => filter.filter === key);

        if (filterIndex > -1) {
            filtersCopy[filterIndex].value = data!.value === "all" ? "" : data!.value;
        } else {
            filtersCopy.push({
                filter: key,
                value: data!.value === "all" ? "" : data!.value,
            });
        }

        dispatch(setPersonaAppDisplayFilter(filtersCopy));
        closePopover();
    };

    // Function to close the popover
    const closePopover = () => setIsFilterOpen(false);

    /**
     * Debounce search
     */
    const debouncedSearch = useCallback(
        debounce((value) => {
            let searchQuery = {};

            if (value) {
                searchQuery = {
                    or: [{ first_name: { contains: value } }, { last_name: { contains: value } }, { username: { contains: value } }],
                    ...convertFiltersToSearchCriteria(personaState.displayFilters),
                };
            }

            fetchPersonaApi(searchQuery, {
                case_insensitive: true,
            });
        }, 500),
        [],
    );

    const clearFilter = (key: string) => {
        // find filter
        const filtersCopy = personaState.displayFilters.filter((filter) => filter.filter !== key);
        dispatch(setPersonaAppDisplayFilter(filtersCopy));
    };

    const loadMorePersonas = () => {
        fetchPersonaApi({}, { page: personaState.personaPage + 1 }, "append");
    };

    return (
        <div className="sidebar h-full">
            <div className="side-nav-sm">
                <h2 className="title">AI Entities</h2>
                <p className="subtitle">Create and manage a new species.</p>
            </div>
            <div className="sidebar-body px-6 overflow-y-auto">
                {/*<Input placeholder="Search Personas" />*/}

                <div className="agents mt-4">
                    {/* <div className='title flex py-3 justify-between'>
            <h2 className='font-semibold'>Arins</h2>
            <Button colorScheme='blue' variant='ghost' size='xs'>
              About
            </Button>
          </div> */}
                    {/* <FormControl mb={3}>
            <Select
              defaultValue={selectedArins}
              isMulti
              isClearable={false}
              name='colors'
              options={agents}
              onChange={onSelectArins}
              className='arin-react-select-container'
              classNamePrefix='arin-react-select'
            />
          </FormControl> */}
                    <div onClick={() => personaSelect(personaState.generalAIEntity)} className={`${isAiEntityActive ? "border-blue-500 bg-blue-50" : "border-gray-200"} font-medium cursor-pointer border mb-3 text-sm p-3 flex justify-between items-center rounded-lg cursor-pointer`}>
                        <p className="flex gap-2 items-center">
                            <TbRobot className="text-lg" />
                            <span>General AI Entity</span>
                        </p>
                        {personaState?.generalAIEntity?.id && (
                            <span className="text-green-500 text-lg">
                                <AiFillCheckCircle />
                            </span>
                        )}
                    </div>
                </div>

                <div className="personas mt-4">
                    <div className="title flex py-3 justify-between">
                        <h2 className="font-semibold">Personas</h2>
                        <Button onClick={createPersona} colorScheme="blue" variant="ghost" size="xs" leftIcon={<FiPlus />}>
                            Create
                        </Button>
                    </div>

                    <div className="grid grid-cols-[auto_32px] gap-[5px]">
                        <div className="input-group relative">
                            <Input borderRadius={"4px"} placeholder="Search Personas.." size={"sm"} onChange={(e) => debouncedSearch(e.target.value)} />
                            <div className="absolute flex items-center bg-white pr-2 top-[1px] right-[1px] bottom-[1px] rounded-[0.375rem]">{personaState.loading && <Spinner size={"sm"} />}</div>
                        </div>
                        <div className="actions">
                            <Popover isOpen={isFilterOpen} placement="bottom-end">
                                <PopoverTrigger>
                                    <IconButton variant={"outline"} icon={<IoFilterOutline size={"18px"} />} aria-label={"Filter"} size={"sm"} onClick={() => setIsFilterOpen(!isFilterOpen)} title="Filter" />
                                </PopoverTrigger>
                                <Portal>
                                    <PopoverContent width={"250px"} shadow="0 6px 24px rgba(0, 0, 0, 0.12)">
                                        <PopoverArrow />
                                        <PopoverHeader>Filters</PopoverHeader>
                                        {/* <PopoverCloseButton onCli /> */}
                                        <PopoverBody pb="20px">
                                            <div className="filter mb-[12px]">
                                                <p className="label mb-[2px]">Type</p>
                                                <ReactSelect
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                    onChange={(value) => onPersonaFilterChange("type", value)}
                                                    defaultValue={{
                                                        label: "All",
                                                        value: "all",
                                                    }}
                                                    options={[
                                                        {
                                                            label: "All",
                                                            value: "all",
                                                        },
                                                        {
                                                            label: "Arin",
                                                            value: "arin",
                                                        },
                                                        {
                                                            label: "Sylis",
                                                            value: "sylis",
                                                        },
                                                    ]}
                                                />
                                            </div>

                                            <div className="filter mb-[12px]">
                                                <p className="label mb-[2px]">Status</p>
                                                <ReactSelect
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                    defaultValue={{
                                                        label: "All",
                                                        value: "all",
                                                    }}
                                                    options={[
                                                        {
                                                            label: "All",
                                                            value: "all",
                                                        },
                                                        {
                                                            label: "Active",
                                                            value: "active",
                                                        },
                                                        {
                                                            label: "Inactive",
                                                            value: "inactive",
                                                        },
                                                    ]}
                                                    onChange={(value) => onPersonaFilterChange("status", value)}
                                                />
                                            </div>
                                            {/* <div className="flex justify-end pt-3 mt-4 pb-1">
                                                        <Button colorScheme="brand" size="sm">Save</Button>
                                                    </div> */}
                                        </PopoverBody>
                                    </PopoverContent>
                                </Portal>
                            </Popover>
                        </div>
                    </div>

                    {personaState.displayFilters.filter((filterMap) => !!filterMap.value).length > 0 && (
                        <div className="filters pt-3 border-b pb-1">
                            <p className="text-[12px] font-medium mb-[2px]">Filters</p>
                            <div className="flex gap-[8px]">
                                {personaState.displayFilters
                                    .filter((filterMap) => !!filterMap.value)
                                    .map((filterMap, index) => (
                                        <Tag
                                            key={`filter-${index}`}
                                            size={"sm"}
                                            variant="subtle"
                                            borderRadius={"100px"}
                                            colorScheme="gray"
                                            // background={"#2196F3"}
                                            // color={"#ffffff"}
                                        >
                                            <TagLabel>
                                                {capitalizeWord(filterMap.filter)}: {capitalizeWord(filterMap.value)}
                                            </TagLabel>
                                            <TagCloseButton onClick={() => clearFilter(filterMap.filter)} />
                                        </Tag>
                                    ))}
                            </div>
                        </div>
                    )}

                    {/* <FormControl mb={3}>
                        <Select
                            isMulti
                            isClearable={false}
                            name="colors"
                            options={personaSelectOptions}
                            onChange={onSelectPersonas}
                            className="arin-react-select-container"
                            classNamePrefix="arin-react-select"
                        />
                    </FormControl> */}

                    <div className="mt-[16px]">
                        {personaState.personas.map((persona, index) => (
                            <PersonaListCard key={"persona-list-card-" + index} data={persona} state={persona.status === "draft" ? "sleep" : persona.status === "deleted" ? "dead" : "active"} isActive={selectedPersonaId === persona.id} onClick={() => personaSelect(persona)} />
                            // <div
                            //     key={index}
                            //     className={`avatar-card ${
                            //         selectedPersonaId === persona.id
                            //             ? "active"
                            //             : ""
                            //     } mb-4 px-4 py-2 rounded-lg`}
                            //     onClick={() => personaSelect(persona)}
                            // >
                            //     <div className="left flex gap-3 items-center">
                            //         <ProfileAvatar size="30px" />
                            //         <h4 className="clone-text font-semibold">
                            //             {persona.first_name +
                            //                 " " +
                            //                 persona.last_name}
                            //         </h4>
                            //     </div>
                            // </div>
                        ))}
                    </div>

                    {personaState.personas.length === 0 && !personaState.loading && (
                        <div className="relative h-[200px]">
                            <div className="empty-state">
                                <div className="flex flex-col items-center absolute top-[50%] transform translate-y-[50%] px-10">
                                    <img src="/img/no_personas.png" className={"mx-auto h-16 mb-6"} alt="No oersonas" />
                                    <h1 className="title font-bold">Nothing to show</h1>
                                    <p className="text-sm text-center text-slate-500">No personas apps. Create new a new persona.</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {personaState.personas?.length > 49 && (
                        <div className="actions flex justify-center py-1">
                            <Button onClick={loadMorePersonas} isLoading={personaState.loading} size="sm" variant="ghost">
                                <span className="text-blue-500">Load more</span>
                            </Button>
                        </div>
                    )}

                    {/*<div>
                        {personasList && personasList.length > 0 ? (personasList.map((persona, index) => (
                            <div key={index} className="avatar-card mb-4 px-4 py-2 rounded-lg">
                                <div className="left flex gap-3 items-center">
                                    <img src={"/user_avatar.png"} alt="avatar" className="profile-image" />
                                    <h4 className="clone-text font-semibold">{persona.name}</h4>
                                </div>
                            </div>)) ) : (<p>No personas yet</p>)}
                    </div>*/}
                </div>
            </div>
        </div>
    );
}
