import { string } from "yup";
import React from "react";

export function convertHeaderStringToList(str: string) {
    const pattern = /\[([^\]]+)]/g;
    const matches = str.match(pattern);

    if (!matches) {
        return [str];
    }

    const result: string[] = [];
    let lastIndex = 0;

    for (const match of matches) {
        const index = str.indexOf(match, lastIndex);

        if (index > lastIndex) {
            result.push(str.substring(lastIndex, index).trim());
        }

        result.push(match);
        lastIndex = index + match.length;
    }

    if (lastIndex < str.length) {
        result.push(str.substring(lastIndex).trim());
    }

    return result;
}

export function capitalizeWord(word: string) {
    if (!word) {
        return "";
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
}

// Define a function that takes an array of strings as a parameter
export function removeDuplicates(list: string[]) {
    // Create an empty object to store the unique strings
    let unique: any = {};
    // Loop through the array
    for (let i = 0; i < list.length; i++) {
        // If the string is not already in the object, add it as a key with a value of true
        if (!unique[list[i]]) {
            unique[list[i]] = true;
        }
    }
    // Return the keys of the object as an array
    return Object.keys(unique);
}

// Function to remove duplicates from an array of objects
export function removeDuplicatesByProperty(arr: any[], key: string) {
    const uniqueValues = new Map();

    for (const obj of arr) {
        const value = obj[key];
        if (!uniqueValues.has(value)) {
            uniqueValues.set(value, obj);
        }
    }

    return Array.from(uniqueValues.values());
}

// A function that takes a string as input and returns true if it is a valid url, false otherwise
export function isValidUrl(url: string) {
    let regex = /^(https?|ftp):\/\/([a-z0-9-]+(\.[a-z0-9-]+)+|localhost)(:\d+)?(\/[^\s]*)?$/i;
    return regex.test(url);
}

export function convertHalfCharactersToBold(inputString: string) {
    // Split the input string into an array of words
    const words = inputString.split(" ");

    // Iterate through each word in the array
    const boldWords = words.map((word) => {
        // Check if the word has at least two characters
        if (word.length >= 2) {
            const wordLength = word.length;
            const halfLength = Math.ceil(wordLength / 2);
            // Get the first two characters of the word and wrap them with <b> tags
            const boldedChars = `<strong>${word.substring(0, halfLength)}</strong>`;

            // Combine the bolded characters with the rest of the word
            const restOfWord = word.substring(halfLength);
            return boldedChars + restOfWord;
        }

        // Return the word as is if it has less than two characters
        return word;
    });

    // Join the array of bolded words back into a string
    const result = boldWords.join(" ");

    return result;
}

export function getMemoryFromNamespaceString(namespace: string) {
    if (!namespace) {
        return { sector: "", location: "" };
    }
    const [_, sector, location] = namespace.split("-axbyzc-");

    return { sector, location };
}

export function getReactSelectOptionsList(arr: any[], keyProp: string, valueProp: string): { value: string; label: string }[] {
    return arr.map((item: any) => {
        return {
            label: capitalizeWord(item[keyProp]),
            value: item[valueProp],
        };
    });
}

export function getReactSelectOptionMap(arr: any[], keyProp: string, valueProp: string, value: string): { value: string; label: string } {
    const item = arr.find((item: any) => item[keyProp] === value);
    return {
        label: capitalizeWord(item[keyProp]),
        value: item[valueProp],
    };
}

function* generateSearchMessages() {
    let count = 0;
    const messages = ["Understanding query and planning retrieval process", "Deep searching through memories", "Found what I was looking for", "Formulating a proper response"];
    while (true) {
        if (count >= messages.length - 1) {
            break;
        }
        ++count;

        yield messages[count];
        yield new Promise((resolve) => setTimeout(resolve, 3000));
    }
}

export function getNextSearchLoadingMessage() {
    const valueGenerator = generateSearchMessages();
    const { value, done } = valueGenerator.next();
    if (!done) {
        if (value instanceof Promise) {
            value.then(() => {
                getNextSearchLoadingMessage();
            });
        } else {
            getNextSearchLoadingMessage();
            return value;
        }
    } else {
        return "";
    }
}

export function truncateFileName(fileName, maxLength = 50) {
    if (fileName.length <= maxLength) {
        return fileName;
    }

    const extensionIndex = fileName.lastIndexOf(".");
    if (extensionIndex > 0) {
        const extension = fileName.slice(extensionIndex);
        const truncatedLength = maxLength - extension.length - 2; // Reserve space for ".."
        const firstPart = fileName.slice(0, truncatedLength / 2);
        const secondPart = fileName.slice(-truncatedLength / 2);
        return `${firstPart}..${secondPart}${extension}`;
    } else {
        return fileName.slice(0, maxLength - 2) + "..";
    }
}

export function countWords(inputString) {
    // Remove leading and trailing whitespace and split the string into words
    const words = inputString.trim().split(/\s+/);

    // Return the number of words
    return words.length;
}

export function extractJSON(data: string) {
    let firstOpen,
        firstClose,
        candidate,
        result: any = null;
    firstOpen = data.indexOf("{");

    if (firstOpen == -1) {
        return null;
    }

    firstClose = data.lastIndexOf("}");

    while (firstClose > firstOpen) {
        candidate = data.substring(firstOpen, firstClose + 1);

        try {
            result = JSON.parse(candidate);

            if (result) {
                if (result.hasOwnProperty("watermark") && result.watermark === "neoworlder") {
                    return result;
                } else {
                    return null;
                }
            }

            return result;
        } catch (e) {
            firstClose = data.substring(0, firstClose).lastIndexOf("}");
        }
    }

    return null;
}

export function transformUnderscoresToSpacesAndCapitalize(input: string) {
    // Replace all underscores with spaces
    const stringWithSpaces = input.replace(/_/g, " ");

    // Split the string into words
    const words = stringWithSpaces.split(" ");

    // Capitalize each word
    const capitalizedWords = words.map((word) => capitalizeWord(word));

    // Join the words back into a string
    const result = capitalizedWords.join(" ");

    return result;
}

export function shortenAPIKeyString(str, maxLength = 16) {
    if (str.length <= maxLength) {
        return str;
    }

    // Calculate the length of the parts before and after the ellipsis
    let partLength = Math.floor((maxLength - 3) / 2);

    // Extract the beginning and ending parts of the string
    let start = str.substring(0, partLength);
    let end = str.substring(str.length - partLength);

    // Return the combined shortened string
    return `${start}...${end}`;
}

export function formatFileSize(bytes: number) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
        return "0 Bytes";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
}

export function getFileNameAndExtension(file: File | string) {
    // Get the file name and split it to get the file extension
    if (typeof file === "string") {
        const fileName = file.split(".");
        return { extension: fileName.pop()?.toLowerCase(), name: fileName[0] };
    } else {
        const fileName = file.name.split(".");
        return { extension: fileName.pop()?.toLowerCase(), name: fileName[0] };
    }
}

export function truncateText(text: string, length = 200) {
    if (text.length < length) {
        return text;
    }
    return text.slice(0, length) + "..";
}
