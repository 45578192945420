import ArinLayout from "../../components/ArinLayout";
import "../../styles/AccountView.component.scss";
import { useEffect } from "react";
import { setMetaTags } from "../../utils/seo.utils";
import { NavLink, Outlet } from "react-router-dom";
import { FiUsers } from "react-icons/fi";
import { BiBrain } from "react-icons/bi";
import { AiOutlinePieChart } from "react-icons/ai";
// import { TbRobot } from "react-icons/tb";
import SideMenuCard from "../../components/SideMenuCard";
import { useSelector } from "react-redux";
import { AccountState } from "../../store/slices/account.slice";
import { FaRegBuilding } from "react-icons/fa";
import { GoHistory } from "react-icons/go";
import { IoCardOutline } from "react-icons/io5";
import { GrConfigure } from "react-icons/gr";
import { PiDeviceTabletSpeakerBold } from "react-icons/pi";

export default function AccountRoot() {
    const accountState: AccountState = useSelector((state: any) => state.account);
    const isOrganizationAdmin = accountState.activeOrganization?.id ? accountState.accountData?.role === "admin" : true;

    useEffect(() => {
        setMetaTags({ title: "Account" });
    }, []);

    return (
        <ArinLayout>
            <div className="side-pane-2-view">
                <div className="left-pane account-sidebar">
                    <div className="sidebar-sm">
                        <div className="side-nav-sm">
                            <h4 className="title">Account</h4>
                            <p className="subtitle">Viewing your Personal workspace</p>
                        </div>
                        <div className="sidebar-body">
                            <ul className="account-links mt-4">
                                <li>
                                    <NavLink to={"/account/profile"} className={({ isActive }) => (isActive ? "active" : "")}>
                                        {({ isActive }) => <SideMenuCard title="Profile" isActive={isActive} leftIcon={<FiUsers className="icon" />} />}
                                    </NavLink>
                                </li>
                                {isOrganizationAdmin && (
                                    <li>
                                        <NavLink to={"/account/members"} className={({ isActive }) => (isActive ? "active" : "")}>
                                            {({ isActive }) => <SideMenuCard title="Members" isActive={isActive} leftIcon={<FiUsers />} />}
                                        </NavLink>
                                    </li>
                                )}
                                {/*<li>*/}
                                {/*    <NavLink*/}
                                {/*        to={'/account/agents'}*/}
                                {/*        className={({isActive}) =>*/}
                                {/*            isActive ? "active" : ""*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <TbRobot className="icon"/>Agents*/}
                                {/*    </NavLink>*/}
                                {/*</li>*/}
                                <li>
                                    <NavLink to={"/account/usage"} className={({ isActive }) => (isActive ? "active" : "")}>
                                        {({ isActive }) => <SideMenuCard title="Usage" isActive={isActive} leftIcon={<AiOutlinePieChart />} />}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/account/api-keys"} className={({ isActive }) => (isActive ? "active" : "")}>
                                        {({ isActive }) => <SideMenuCard title="API Keys" isActive={isActive} leftIcon={<GrConfigure />} />}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="no-page" to={"/account/subscription"}>
                                        Subscriptions
                                    </NavLink>
                                    <ul className="account-links sub-links">
                                        {isOrganizationAdmin && (
                                            <li>
                                                <NavLink to={"/account/subscription/organization"} className={({ isActive }) => (isActive ? "active" : "")}>
                                                    {({ isActive }) => <SideMenuCard title="Organization" isActive={isActive} leftIcon={<FaRegBuilding />} />}
                                                </NavLink>
                                            </li>
                                        )}
                                        <li>
                                            <NavLink to={"/account/subscription/payment-methods"} className={({ isActive }) => (isActive ? "active" : "")}>
                                                {({ isActive }) => <SideMenuCard title="Payment Methods" isActive={isActive} leftIcon={<IoCardOutline />} />}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={"/account/subscription/history"} className={({ isActive }) => (isActive ? "active" : "")}>
                                                {({ isActive }) => <SideMenuCard title="History" isActive={isActive} leftIcon={<GoHistory />} />}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={"/account/brains"} className={({ isActive }) => (isActive ? "active" : "")}>
                                        {({ isActive }) => <SideMenuCard title="Neural Pathways" isActive={isActive} leftIcon={<BiBrain />} />}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/account/perceptors"} className={({ isActive }) => (isActive ? "active" : "")}>
                                        {({ isActive }) => <SideMenuCard title="Perceptors" isActive={isActive} leftIcon={<PiDeviceTabletSpeakerBold />} />}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="main-pane upload-content-section position-relative">
                    <div className="main-pane-contents">
                        <Outlet />
                    </div>
                </div>
            </div>
        </ArinLayout>
    );
}
