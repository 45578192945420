import { Button } from "@chakra-ui/react";
import { BsUiChecks } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function SubscriptionSuccessView() {
    const navigate = useNavigate();
    const backToApp = () => {
        navigate("/");
    };

    return (
        <div className="h-screen w-screen relative bg-gray-50 flex flex-col justify-center items-center">
            <img src="/neo_ai_logo_black.png" alt="" className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]" />
            <BsUiChecks className="text-green-600" size={"55px"} />
            <h1 className="text-green-900 text-[22px] mt-[36px] mb-[8px] font-semibold">Your subscription is now active</h1>
            <p className="mb-[40px] text-slate-500 max-w-[450px] text-center text-[15px]">You have successfully subscribed to Neo AI</p>
            <Button onClick={backToApp} variant="outline">
                Go to Neo AI
            </Button>
        </div>
    );
}
