import { Checkbox, Progress, Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonaState, updatePersona } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";
import { BsFiletypeTxt } from "react-icons/bs";
import { IoRefreshSharp } from "react-icons/io5";
import KeycloakClient from "../../providers/auth/keycloak-client";

interface PersonaPersonalityKnowledgeProps {
    markAsCompleted?: (completedStatus: boolean) => void;
}

const PersonaKnowledge = ({ markAsCompleted }: PersonaPersonalityKnowledgeProps) => {
    const dispatch = useDispatch();
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState<any>();
    const { httpClient } = useHttpClient();
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const toast = useToast();
    const [residentOfLootverse, setResidentOfLootverse] = useState<boolean>(false);

    useEffect(() => {
        // If personaAttributeForm knowledge is set,
        if (personaState.personaAttributesForm.knowledge) {
            // set upload complete
            setUploadComplete(true);
            // Mark tab as completed
            if (markAsCompleted) {
                markAsCompleted(true);
            }
        } else {
            setUploadComplete(false);
            if (markAsCompleted) {
                markAsCompleted(false);
            }
        }
    }, [personaState.personaAttributesForm]);

    const onFilePicked = (e) => {
        const file: any = e.target.files[0];
        setSelectedFile(file);
        // If file
        if (file) {
            uploadKnowledgeFile(file);
        }
    };

    const openFilePicker = () => {
        inputFileRef.current?.click();
    };

    const uploadKnowledgeFile = async (file) => {
        const formData = new FormData();
        // Update the formData object
        formData.append("memoryFile", file, file.name);
        formData.append("persona", personaState.personaAttributesForm.id as string);
        formData.append("resident_of_lootverse", residentOfLootverse.toString());
        // formData.append("fileName", uploadForm.selectedFile?.meta.name || '');

        // upload file
        const { data } = await httpClient.post(`api/v1/persona-knowledge/add-persona-knowledge`, formData, {
            onUploadProgress: function (progressEvent) {
                setUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent as any).total));
            },
            headers: {
                Authorization: `Bearer ${await KeycloakClient.getInstance().getAuthUserToken()}`,
            },
        });
        // show toast
        toast({
            title: "File uploaded successfully",
            status: "success",
        });
        // set upload complete
        setUploadComplete(true);
        dispatch(updatePersona({ id: personaState.personaAttributesForm.id, knowledge: "knowledge uploaded" }));
        // Mark tab as completed
        if (markAsCompleted) {
            markAsCompleted(true);
        }
    };

    return (
        <div className="">
            <p className="text-[14px] mb-2 text-slate-500">This entity has for foundational morals the standard prime directives by NeoWorlder. This cannot be changed and is non-downloadable.</p>

            <p className="text-[14px] mb-2 text-slate-500">
                The entity also has a contextual knowledge of its environment contained in the Echoes of Arcadia, a series of contextual events that occurs where the synthetic lifeforms and artificially intelligent beings reside (their primary place of residence). The Echoes of Arcadia contains a deep and rich history of approximately 1.5 million words, or twice the Bible and was developed specifically for this purpose. The stories can be seen at lootverse.com.
            </p>
            <p className="text-[14px] mb-6 text-slate-500">Provide additional contextual information? This contextual information will be treated as a secondary place of residence if outside of lootverse or an extension of the Arcadian stories if inside of Lootverse.</p>
            {/* Checkbox */}
            {!uploadComplete && (
                <Checkbox isChecked={residentOfLootverse} onChange={(e) => setResidentOfLootverse(e.target.checked)}>
                    <p className="text-[14px] text-slate-500">Residence outside of Lootverse?</p>
                </Checkbox>
            )}
            <div className="upload-section mt-8">
                <input ref={inputFileRef} style={{ display: "none" }} type="file" accept=".txt" onChange={onFilePicked} />
                {selectedFile ? (
                    <div className="px-4 overflow-hidden py-2 relative flex justify-between bg-gray-100 border border-gray-300 rounded">
                        <div className="flex gap-2 my-2 items-center">
                            <BsFiletypeTxt size="24px" />
                            <p className="text-[14px] truncate w-[160px text-slate-900">{selectedFile.name}</p>
                        </div>
                        <div className="actions flex items-center">
                            {uploadComplete && (
                                <Button onClick={openFilePicker} pr="0" variant="ghost">
                                    <IoRefreshSharp size="18px" />
                                </Button>
                            )}
                            {/* <Button
                                    pr="0"
                                    pl="0"
                                    variant="ghost"
                                    className="text-red-500"
                                >
                                    <AiOutlineDelete
                                        className="text-red-500"
                                        size="18px"
                                    />
                                </Button> */}
                        </div>

                        {!uploadComplete && (
                            <div className="progress-bar absolute bottom-0 left-0 right-0 h-[4px]">
                                <Progress value={uploadProgress} size="xs" />
                            </div>
                        )}
                    </div>
                ) : (
                    !uploadComplete && (
                        <button onClick={openFilePicker} className="px-4 py-2 bg-gray-100 border border-gray-300 rounded">
                            Upload only .txt files
                        </button>
                    )
                )}
            </div>
            {/* User Message on the Right */}
            {/* <Formik
                    initialValues={{ personalityKnowledge: "" }}
                    onSubmit={() => console.log("submit")}
                    innerRef={formikRef}
                >
                    {(formikProps) => (
                        <Form>
                            <Field
                                name={"personalityKnowledge"}
                                validate={(value) => {
                                    if (!value) {
                                        return `Please insert the persona's knowledge`;
                                    }
                                }}
                            >
                                {({ field, form }) => (
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            form.errors[
                                                "personalityKnowledge"
                                            ] &&
                                            form.touched["personalityKnowledge"]
                                        }
                                    >
                                        <Textarea
                                            {...field}
                                            className="mt-5"
                                            placeholder="Type your message here..."
                                            resize="none"
                                            rows={5}
                                        />
                                        <FormErrorMessage>
                                            {
                                                form.errors[
                                                    "personalityKnowledge"
                                                ]
                                            }
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Form>
                    )}
                </Formik> */}
        </div>
    );
};

export default PersonaKnowledge;
