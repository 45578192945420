import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import React from "react";
import "../../styles/CustomTabs.component.scss";

type TabInfo = {
    name: string;
    content: React.ReactNode;
};

type CustomTabsProps = {
    tabs: TabInfo[];
};

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs }) => {
    return (
        <Tabs variant="enclosed-colored" w="full" h="full">
            <TabList className="border-b px-[20px] flex">
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        // className={
                        //     index === tabs.length - 1
                        //         ? "last-tab"
                        //         : "tab-header border-r border-black "
                        // }
                        // _selected={{ bg: "gray-200", fontWeight: "bold" }}
                    >
                        {tab.name}
                    </Tab>
                ))}
            </TabList>

            <TabPanels className="tab-panels-container">
                {tabs.map((tab, index) => (
                    <TabPanel className="tab-panel-body h-full overflow-y-auto" key={`tabpanel-${index}`}>
                        {tab.content}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};

export default CustomTabs;
