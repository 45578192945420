import { useEffect, useState } from "react";
import { useHttpClient } from "../../../utils/http.utils";
import { Stack, Skeleton, Button, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, Tag } from "@chakra-ui/react";
import { formatCurrency } from "../../../utils/number.utils";
import { formatDate } from "../../../utils/date.utils";
import { FiDownload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { AccountState } from "../../../store/slices/account.slice";

export default function SubscriptionHistoryView() {
    const { listUserSubscriptionHistoryApi } = useHttpClient();
    const [history, setHistory] = useState<any[]>([]);
    const [historyLoading, setHistoryLoading] = useState(true);
    const accountState: AccountState = useSelector((state: any) => state.account);

    useEffect(() => {
        const fetchSubscriptionHistory = () => {
            if (history.length < 1 && accountState.subscription?.id) {
                // setHistoryLoading(true);
                listUserSubscriptionHistoryApi()
                    .then((data) => {
                        // console.log(data);
                        console.log(data);
                        setHistory(data as any[]);
                    })
                    .finally(() => {
                        setHistoryLoading(false);
                    });
            } else {
                setHistoryLoading(false);
            }
        };

        fetchSubscriptionHistory();
    }, []);

    const downloadReceipt = (url: string) => {
        window.open(url);
    };

    return (
        <div className="relative h-full">
            <div className="side-nav-sm with-action">
                <div>
                    <h4 className="title">Subscription History</h4>
                    <p className="subtitle">View your payment history</p>
                </div>
                <div className="actions"></div>
            </div>

            {!historyLoading && history.length === 0 && (
                <div className="empty-state">
                    <div className="empty-content">
                        <img src="/credit-card.png" alt="" className="icon" />
                        <h2 className="title text-2xl font-bold mt-3">No History</h2>
                        <p className="text-slate-500 mt-3">Your subscription history will display here.</p>
                    </div>
                </div>
            )}
            {/* show element */}
            {!historyLoading && history.length > 0 && (
                <div className="row px-4 mt-8">
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Invoice num.</Th>
                                    <Th>Amount</Th>
                                    <Th>DATE</Th>
                                    <Th>status</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {!historyLoading &&
                                    history.map((record, index) => (
                                        <Tr key={record.id}>
                                            <Td className="inv font-medium text-blue-400">#{record.number}</Td>
                                            <Td className="date">{formatCurrency(record.amount_paid / 100)}</Td>

                                            <Td className="amount">{formatDate(record.created * 1000, "LLL")}</Td>
                                            <Td>
                                                <Tag colorScheme="green" size="sm">
                                                    {record.status}
                                                </Tag>
                                            </Td>
                                            <Td className="actions">
                                                <Button leftIcon={<FiDownload size="18px" className="" />} onClick={() => downloadReceipt(record.invoice_pdf)} variant="link" colorScheme="blue" size="sm" style={{ fontWeight: 400 }}>
                                                    Download receipt
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {/* Loading Skeleton */}
            {historyLoading && (
                <div className="loading px-8 pt-8">
                    <Stack>
                        <Skeleton height="30px" />
                        <Skeleton height="5px" />
                        <Skeleton height="30px" />
                        <Skeleton height="5px" />
                        <Skeleton height="30px" />
                        <Skeleton height="5px" />
                    </Stack>
                </div>
            )}
        </div>
    );
}
