import ReactSelect from "react-select";
import "../styles/UsageProgressBar.component.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PersonaModel } from "../models/persona.model";
import { AccountState } from "../store/slices/account.slice";
import { ResponsiveLine } from "@nivo/line";
import { formatDate } from "../utils/date.utils";
import { config } from "../config";
import { getPersonaFullName } from "../utils/personas.utils";
import { useHttpClient } from "../utils/http.utils";

interface LineData {
    id: string;
    color: string;
    data: { x: string; y: number }[];
}

export default function UserUsageChart() {
    const { listCreditUsageApi } = useHttpClient();
    const personaState = useSelector((state: any) => state.persona);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const personas: PersonaModel[] = personaState.personas;
    const [personaOptions, setPersonaOptions] = useState<any[]>([]);
    const [usersOptions, setUsersOptions] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [selectedPersona, setSelectedPersona] = useState<string>("all");
    const [selectedFilter, setSelectedFilter] = useState<string>("Words");
    const [creditUsage, setCreditUsage] = useState<any[]>([]);
    const [lineData, setLineData] = useState<LineData[]>([]);
    let personaOptionsTempList = useRef<any[]>([]);
    let usersTempList = useRef<any[]>([]);

    useEffect(() => {
        // Set persona options
        personaOptionsTempList.current = personas.map((persona: PersonaModel) => ({
            label: getPersonaFullName(persona),
            value: persona.id,
        }));
        personaOptionsTempList.current.unshift({ value: "all", label: "All" });
        setPersonaOptions(personaOptionsTempList.current);

        if (accountState.activeOrganization?.members) {
            usersTempList.current = accountState.activeOrganization?.members?.map((member) => ({
                label: member.user.email,
                value: member.user.id,
            }));
        }
        usersTempList.current.unshift({ label: accountState.accountData?.email, value: accountState.accountData?.id });
        setUsersOptions(usersTempList.current);

        loadTransactionsData();
        // set the line data
        setLineData([{ id: "user-usage", color: "rgba(0, 0, 0, 0.4)", data: [] }]);
    }, []);

    const loadTransactionsData = async () => {
        // get user ids
        const userIds = usersTempList.current.map((user) => user.value);
        let usageData = await listCreditUsageApi(userIds);

        // load chart Data
        loadChartData(usageData, { personaId: selectedPersona, filter: selectedFilter });
        // set to state
        setCreditUsage(usageData);
        // set chart data
    };

    const loadChartData = (data, { personaId = selectedPersona, filter = selectedFilter }) => {
        let creditUsageData = data ? data : creditUsage,
            usageList: any[] = [],
            vertices: { x: string; y: number }[] = [];

        if (personaId !== "all") {
            usageList = creditUsageData.filter((usage) => usage.external_entity_id === personaId);
        } else {
            usageList = creditUsageData;
        }
        // start filtering
        vertices = usageList.map((record) => {
            // find persona
            if (filter.toLowerCase() === "words") {
                const persona = personas.find((persona) => persona.id === record.external_entity_id);
                // check if ther person
                const pricePerWord = persona?.pricing_per_word || config.creditPerWord;
                return {
                    x: formatDate(record.created_at, "MMM DD, YY"),
                    y: Number(record.amount) / Number(pricePerWord),
                };
            } else {
                return {
                    x: formatDate(record.created_at, "MMM DD, YY"),
                    y: record.amount,
                };
            }
        });

        setLineData([{ id: "user-usage", color: "rgba(0, 0, 0, 0.4)", data: vertices }]);
    };

    const onUserOptionSelect = (personaId) => {
        loadChartData(creditUsage, { personaId, filter: selectedFilter });
        setSelectedUser(personaId);
    };

    const onPersonaSelect = (personaId) => {
        loadChartData(creditUsage, { personaId, filter: selectedFilter });
        setSelectedPersona(personaId);
    };

    const onFilterSelect = (filter) => {
        loadChartData(creditUsage, { personaId: selectedPersona, filter });
        setSelectedFilter(filter);
    };

    return (
        <div className="usage-progress pt-4">
            <div className="flex justify-between h-[100px]">
                <div className="header"></div>
                <div className={"flex gap-2 items-center"}>
                    <div className="w-64">
                        <ReactSelect placeholder="User" className="arin-react-select-container" classNamePrefix="arin-react-select" defaultValue={usersOptions[0]} options={usersOptions} onChange={(val) => onUserOptionSelect((val?.value as string) || "all")} />
                    </div>
                    <div className="w-[180px]">
                        <ReactSelect placeholder="Persona" className="arin-react-select-container" classNamePrefix="arin-react-select" defaultValue={personaOptions[0]} options={personaOptions} onChange={(val) => onPersonaSelect((val?.value as string) || "all")} />
                    </div>
                    <ReactSelect
                        placeholder="Persona"
                        className="arin-react-select-container"
                        classNamePrefix="arin-react-select"
                        defaultValue={{ value: "words", label: "Words" }}
                        options={[
                            { value: "Words", label: "Words" },
                            { value: "T$", label: "T$" },
                        ]}
                        onChange={(val) => onFilterSelect(val?.value || "Words")}
                    />
                </div>
            </div>

            <div className="chart-container">
                <div className="chart" style={{ height: "40vh", width: "100%" }}>
                    <ResponsiveLine
                        data={lineData}
                        margin={{ top: 50, right: 30, bottom: 60, left: 60 }}
                        xScale={{ type: "point" }}
                        yScale={{
                            type: "linear",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                        }}
                        curve="cardinal"
                        enableArea={true}
                        areaBaselineValue={60}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: selectedFilter,
                            legendOffset: -40,
                            legendPosition: "middle",
                        }}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 15,
                        }}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                    />
                </div>
            </div>
        </div>
    );
}
