import { useRef, useState } from "react";
import { AutoInteractionMessage } from "../../models/collaboration.model";
import { ButtonGroup, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Textarea } from "@chakra-ui/react";
import { formatCollaborateMessage, formatCollaborationAppFileLink } from "../../utils/collaborate/parser";
import { BsQuestionCircle } from "react-icons/bs";
import classnames from "classnames";
import hotToast from "react-hot-toast";

interface Props {
    message: AutoInteractionMessage;
    hideActions: boolean;
    onComplete?: (values: DecisionFormValues) => void;
}

interface StepFormProps {
    message: JSX.Element | JSX.Element[];
    file?: JSX.Element | JSX.Element[];
}

export interface DecisionFormValues {
    startWithPerceptorInput: boolean;
    isSkillNonChangeable: boolean;
    additionalInstructions: string;
    useOnlyAvailableDeterministicTools: boolean;
    proceedWithAction: "yes" | "no";
}

const StepForm = ({ message, file }: StepFormProps) => (
    <div className="character animate-fade-in">
        <p className="character-message">{message}</p>
        {file && <p className="character-message mt-[10px] character-message-file-link">{file}</p>}
    </div>
);

const formElements = [
    <Popover placement="right-end" colorScheme="blue">
        <PopoverTrigger>
            <div className="cursor-pointer w-auto inline-flex gap-1 items-center">
                Is the first cell to start with a perceptor input?
                <BsQuestionCircle size={"16px"} />
            </div>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader className="flex gap-1 items-center">
                <BsQuestionCircle size={"15px"} />
                &nbsp;Info
            </PopoverHeader>
            <PopoverBody>A perceptor is an external third-party input into the cell to kickstart its operations, for example an email</PopoverBody>
        </PopoverContent>
    </Popover>,
    <Popover placement="right-end" colorScheme="blue">
        <PopoverTrigger>
            <div className="cursor-pointer w-auto inline-flex gap-1 items-center">
                Is this skill non-changeable, or to be wrapped with additional instructions?
                <BsQuestionCircle size={"16px"} />
            </div>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader className="flex gap-1 items-center">
                <BsQuestionCircle size={"15px"} />
                &nbsp;Info
            </PopoverHeader>
            <PopoverBody>If the skill is general and you’d like to feed a specific type of information to start the cellular process, then say yes. Otherwise, the system can simply start with the goal of the first cell automatically</PopoverBody>
        </PopoverContent>
    </Popover>,
    <Popover placement="bottom-end" colorScheme="blue">
        <PopoverTrigger>
            <div className="cursor-pointer w-auto inline-flex gap-1 items-center">
                Do you want to only use the available deterministic tools, or get the general AI entity to create its own tools
                <BsQuestionCircle size={"16px"} />
            </div>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader className="flex gap-1 items-center">
                <BsQuestionCircle size={"15px"} />
                &nbsp;Info
            </PopoverHeader>
            <PopoverBody>
                If you want the creativity, the first time you run the collab before publishing it to a persona’s brain, may take significant time and additional cost to produce the first time. Don’t worry you can stop the process after a batch of 10 iterations. In this process, code will be generated automatically, then self healed up until the point that it may be used. Once done, however, the skill is saved and available to you such that a second run of the skill will be much faster the same
                as a deterministic tool. If you choose a deterministic tool, you may be limited in the scope of what you can do and in some event, for complex skills, you may not achieve the required output. For simple skills, you can choose deterministic, for complex one, choose creativity
            </PopoverBody>
        </PopoverContent>
    </Popover>,
];

const enum FormStep {
    StartWithPerceptorInput = 1,
    IsSkillNonChangeable = 2,
    AdditionalInstructions = 3,
    UseOnlyAvailableDeterministicTools = 4,
    ProceedWithAction = 5,
}

const InteractionMessageDecision: React.FC<Props> = ({ message, hideActions, onComplete }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [animating, setAnimating] = useState(false);
    const additionalInstructionsRef = useRef<HTMLTextAreaElement>(null);
    const [formValues, setFormValues] = useState<DecisionFormValues>({
        startWithPerceptorInput: false,
        isSkillNonChangeable: false,
        additionalInstructions: "",
        useOnlyAvailableDeterministicTools: false,
        proceedWithAction: "no",
    });

    const changeStep = (step?: FormStep) => {
        // go to next button
        if (currentStep < FormStep.ProceedWithAction) {
            setAnimating(true);
            setTimeout(() => {
                setCurrentStep(step ? step : currentStep + 1);
                setAnimating(false);
            }, 200);
        }
    };

    const handleDecision = (decision: "yes" | "no") => {
        // Handle this
        switch (currentStep) {
            case FormStep.StartWithPerceptorInput:
                setFormValues({ ...formValues, startWithPerceptorInput: decision === "yes" });
                /**
                 * If decision is yes, navigate to FormStep.UseOnlyAvailableDeterministicTools
                 */
                if (decision === "yes") {
                    changeStep(FormStep.UseOnlyAvailableDeterministicTools);
                    return;
                }
                break;
            case FormStep.IsSkillNonChangeable:
                setFormValues({ ...formValues, isSkillNonChangeable: decision === "yes" });
                /**
                 * If decision is no, navigate to FormStep.UseOnlyAvailableDeterministicTools
                 */
                if (decision === "no") {
                    changeStep(FormStep.UseOnlyAvailableDeterministicTools);
                    return;
                }
                break;
            case FormStep.AdditionalInstructions:
                const value = additionalInstructionsRef.current!.value;
                if (!value) {
                    hotToast.error("Please enter additional instructions");
                    return;
                }
                setFormValues({ ...formValues, additionalInstructions: value });
                break;
            case FormStep.UseOnlyAvailableDeterministicTools:
                setFormValues({ ...formValues, useOnlyAvailableDeterministicTools: decision === "yes" });
                break;
            case FormStep.ProceedWithAction:
                setFormValues({ ...formValues, proceedWithAction: decision });
                // Out form values
                if (typeof onComplete === "function") {
                    onComplete({ ...formValues, proceedWithAction: decision });
                }
                break;
            default:
                break;
        }
        /**
         * Change form step
         */
        changeStep();
    };

    return (
        <div className="ai-message-container">
            <p className="text-[14px] font-bold pt-[16px] m-0 px-[16px]">{message.persona || ""}</p>
            <div style={{ paddingTop: 0 }} className={classnames({ "ai-message": true, "animate-fade-out": animating })}>
                {currentStep === FormStep.StartWithPerceptorInput && <StepForm message={formElements[0]} />}
                {currentStep === FormStep.IsSkillNonChangeable && <StepForm message={formElements[1]} />}
                {currentStep === FormStep.AdditionalInstructions && (
                    <div className="input-field animate-fade-in pt-[10px] pr-[40px]">
                        <p className="text-[14px] font-medium text-slate-800 mb-1">Additional instructions</p>
                        <Textarea ref={additionalInstructionsRef} placeholder="Enter specific information" className="resize-none" rows={3} />
                    </div>
                )}
                {currentStep === FormStep.UseOnlyAvailableDeterministicTools && <StepForm message={formElements[2]} />}
                {currentStep === FormStep.ProceedWithAction && <StepForm message={formatCollaborateMessage(message.message)} file={formatCollaborationAppFileLink(message.file)} />}
                {!hideActions && currentStep !== FormStep.AdditionalInstructions && (
                    <div className="message-action pt-4 mb-2">
                        <ButtonGroup>
                            <Button onClick={() => handleDecision("yes")} size="sm" background="blue.50" color="blue.500">
                                Yes
                            </Button>
                            <Button onClick={() => handleDecision("no")} size="sm" background="red.50" color="red.500">
                                No
                            </Button>
                        </ButtonGroup>
                    </div>
                )}
                {currentStep === FormStep.AdditionalInstructions && (
                    <div className="message-action pt-4 mb-2">
                        <Button onClick={() => handleDecision("yes")} size="sm" background="blue.50" color="blue.500">
                            Next
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InteractionMessageDecision;
