// GenericModal.js
import React, { useEffect, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Skeleton, Stack } from "@chakra-ui/react";
import { useGenericModal } from "./GenericModalContext";
import { AiTwotoneCheckCircle } from "react-icons/ai";
import { PaymentMethodModel } from "../../models/payment.model";
import { useSelector } from "react-redux";
import { useHttpClient } from "../../utils/http.utils";
import PaymentMethodForm from "../accounts/PaymentMethodForm";
import { AccountState } from "../../store/slices/account.slice";
import hotToast from "react-hot-toast";

const PaywallModal = () => {
    const { listSubscriptionPricesApi, createSubscriptionSessionApi } = useHttpClient();
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const accountState: AccountState = useSelector((state: any) => state.account);

    const [subscriptionPrices, setSubscriptionPrices] = useState<any[]>([]);
    const [isLoadingSubscriptionSession, setIsLoadingSubscriptionSession] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);

    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;
    const { isPaywallOpen, closePaywallModal } = useGenericModal();

    useEffect(() => {
        const fetchSubscriptionPrices = async () => {
            const data = await listSubscriptionPricesApi();
            if (Array.isArray(data)) {
                setSubscriptionPrices(data);
            }
        };

        if (subscriptionPrices.length === 0) {
            fetchSubscriptionPrices();
        }
    }, []);

    const createSubscriptionSession = () => {
        /**
         * If the user has not configured payment method,
         * return and ask them to add a payment method
         */
        if (!accountState.activeOrganization?.id) {
            hotToast.error("Please set up your organization first");
            window.location.href = "/account/subscription/organization";
            return;
        }

        if (paymentMethods.length < 1) {
            hotToast.error("Please add a payment method first");
            window.location.href = "/account/subscription/payment-methods";
            return;
        }

        if (subscriptionPrices.length > 0) {
            setIsLoadingSubscriptionSession(true);

            createSubscriptionSessionApi({
                subscription_price: subscriptionPrices[0].id,
                organization: accountState.activeOrganization?.id,
            })
                .then((res) => {
                    if (res?.session_url) {
                        window.location.href = res.session_url;
                    }
                })
                .catch(() => {
                    setIsLoadingSubscriptionSession(false);
                });
        }
    };

    return (
        <>
            <Modal size={"lg"} isCentered={true} isOpen={isPaywallOpen} onClose={closePaywallModal} closeOnEsc={false} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <div className="border-b py-2">
                            <h2 className="text-[16px] font-semibold">Upgrade your Plan</h2>
                            <h4 className="text-[14px] text-slate-400">You need an active subscription to have access to feature</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody pt="0">
                        <div className="bg-white relative h-[450px]">
                            <div className="">
                                {subscriptionPrices.length > 0 ? (
                                    <div className="flex items-end pb-[16px]">
                                        <h2 className="text-[32px] leading-[1] font-semibold">${subscriptionPrices[0]?.unit_amount / 100}</h2>
                                        <p className="text-slate-600 mb-1 leading-[1]">/user/{subscriptionPrices[0]?.recurring?.interval || "month"}</p>
                                    </div>
                                ) : (
                                    <div className="flex items-end mb-6 pt-4">
                                        <Stack>
                                            <Skeleton height="20px" />
                                        </Stack>
                                    </div>
                                )}
                            </div>
                            <div className="border-t pt-4">
                                <div className="flex gap-2 items-center mb-2">
                                    <AiTwotoneCheckCircle color="#3caf50" className="text-green-500" size="16px" />
                                    <p className="text-[14px]">Interact with all synthetic life forms or arins without invitation</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <AiTwotoneCheckCircle size="16px" />
                                    <p className="text-[14px]">Create and license Personas and other synthetic life forms</p>
                                </div>
                            </div>
                            <div className="absolute bottom-[18px] left-[0] right-[0]">
                                <Button onClick={createSubscriptionSession} isLoading={isLoadingSubscriptionSession} colorScheme="brand" className="w-full" loadingText="Loading">
                                    Subscribe
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* add payment method form */}
            <PaymentMethodForm isOpen={isAddFormOpen} onClose={() => setIsAddFormOpen(false)} />
        </>
    );
};

export default PaywallModal;
