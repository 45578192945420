import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "./views/ErrorBoundary";
import KeycloakWrapper from "./views/keycloak-wrapper";
import PaywallModal from "./components/modals/PaywallModal";

function App() {
    // get organizationId from local storage

    // const foldersState = useSelector((state) => (state as any).folders.folders);

    // const memoriesTree = useSelector((state) => (state as any).uploadedData.memoriesTree);

    useEffect(() => {
        setTimeout(() => {
            const loadingEl = document.getElementById("loading-el");
            loadingEl?.remove();
        }, 2500);
    }, []);

    return (
        <div id="App">
            <KeycloakWrapper>
                <PaywallModal />
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </KeycloakWrapper>
        </div>
    );
}

export default App;
